import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Numeral from "numeral";

import {
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Alert,
} from "reactstrap";

import { put } from "../../../utils/DeApi";
import { getStudentId } from "../../../utils/AuthManager";

import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import Loader from "../../Loader/Loader";
import { getSchoolMonths } from "../../../enumerator/enumerator";

class Savings extends Component {
  static propTypes = {
    student: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      savings: props.student.parentSavings,
    };
    this.handleSavingsChange = this._handleSavingsChange.bind(this);
    this.submitSavings = this._submitSavings.bind(this);
  }

  _handleSavingsChange(event) {
    let savings = Numeral(event.target.value).value() || 0;
    if (savings >= 0)
      this.setState({
        savings: savings,
        savingsValid: savings && savings >= 0,
      });
  }

  _submitSavings(event) {
    event.preventDefault();
    this.updateStudent();
  }

  updateStudent() {
    const { onStudentUpdated } = this.props;
    const { savings } = this.state;

    this.setState({ error: "", isLoading: true });

    let studentPromise = put(
      "/profile",
      getStudentId({ parentSavings: savings })
    );
    studentPromise.promise
      .then((response) => {
        this.setState({ error: "", isLoading: false }, () =>
          onStudentUpdated(response.data)
        );
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(studentPromise);
  }

  componentDidMount() {
    Numeral.defaultFormat("$0,0");
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
  }

  render() {
    const { savings, savingsValid, savingsSubmited, isLoading, error } =
      this.state;

    if (isLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;
    return (
      <Fragment>
        <p>
          How much has your family saved (or do you plan to save) for college?
        </p>
        <p className="text-muted">
          Include all college savings from 529 plans and other accounts. Do not
          include home equity or retirement.
        </p>
        <Form onSubmit={this.submitSavings}>
          <FormGroup>
            <Input
              id="parentSaving"
              type="text"
              onChange={this.handleSavingsChange}
              value={Numeral(savings).format()}
              disabled={isLoading}
              invalid={!savingsValid && savingsSubmited}
              placeholder="Parent Saving"
            />
            <FormFeedback>
              This should be a number greater than or equal to 0
            </FormFeedback>
          </FormGroup>

          <Alert color="primary">
            <p className="mb-0">
              This is equal to {Numeral(savings / 4).format()} per year or{" "}
              {Numeral(savings / getSchoolMonths()).format()} per month from
              savings.
            </p>
          </Alert>

          <FormGroup>
            <Button
              color="primary"
              size="sm"
              type="submit"
              disabled={isLoading}
              block
            >
              Submit
            </Button>
          </FormGroup>
        </Form>
      </Fragment>
    );
  }
}

export default Savings;
