import React, { Component } from "react";
import * as echarts from "echarts";
import "./ValueGaugeSummary.scss";
import Numeral from "numeral";

class ValueGaugeSummary extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};
  }

  componentDidMount() {
		const { onFinishedEvent } = this.props

    Numeral.defaultFormat("$0,0");
    this.chart = echarts.init(this.el);

		if (onFinishedEvent) {
			this.chart.on('finished', () => {
				let src = this.chart.getDataURL({
					pixelRatio: 2,
					backgroundColor: '#fff'
				})

				onFinishedEvent(src)
			})
		}

    this.renderGauge();
  }

  componentDidUpdate(prevProps) {
    if (this.props.financingOption !== prevProps.financingOption) {
      this.renderGauge();
    }
  }

  render() {
    return (
      <div className="ValueGaugeSummary">
        <div
          className="value-gauge-container center-block"
          ref={(el) => (this.el = el)}
        />
      </div>
    );
  }

  renderGauge() {
    const { data } = this.props;

    const option = {
      series: [
        {
          type: "gauge",
          startAngle: 225,
          endAngle: -45,
          min: 0,
          max: 1,
          radius: "90%",
          splitNumber: 10,
          axisLine: {
            lineStyle: {
              width: 30,
              color: [
                [0.333, "#FF6E76"],
                [0.667, "#FDDD60"],
                [1, "#7CFFB2"],
              ],
            },
          },
          center: ["50%", "50%"],
          pointer: { length: "60%", width: 8 },
          axisTick: { show: false },
          splitLine: { show: false },
          axisLabel:  { show: false },
          title: { show: false },
          detail: {
            fontSize: 24,
            fontWeight: "bold",
            color: "#000",
            offsetCenter: [0, "45%"],
            valueAnimation: true,
            formatter: function (value) {
              return isNaN(value) ? "N/A" : Math.round(value * 100);
            },
          },
          data: data,
        },
      ],
    };

    this.chart.setOption(option);
  }
}

export default ValueGaugeSummary;
