import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect, Link } from "react-router-dom";
import "./StepProgressBar.scss";
import { Row, Col, Button } from "reactstrap";
import { withRouter } from "react-router-dom";

import { getPartnerId } from "../../../enumerator/WidgetConfigurations";

class StepProgressBar extends Component {
  static propTypes = {
    financingOption: PropTypes.object,
    step: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.subscribedPromises = [];
  }

  getStepStatus(marker) {
    const { step } = this.props;
    if (step === marker) return "active";
    if (step === marker - 0.5) return "current";
    if (marker < step) return "visited";
    return "";
  }

  getStepParams(selectedStep) {
    const { scenario, financingOption } = this.props;
    const foId =
      financingOption?.id || financingOption?.preferredLoanId || null;
    let redirectTo = "",
      title = "",
      description = "",
      stepStatus = "";

    switch (selectedStep) {
      case 1:
        redirectTo = `/`;
        title = "Your Colleges";
        description = "Search for a college.";
        stepStatus = this.getStepStatus(1);
        break;

      case 2:
        redirectTo = scenario ? `/plans/${scenario.id}` : null;
        title = "College Pricing";
        description = "Here are your estimated costs for the entire degree.";
        stepStatus = this.getStepStatus(2);
        break;

      case 3:
        redirectTo = scenario?.netPrice
          ? `/plans/${scenario.id}/funding-gap`
          : null;
        title = "Your Financial Resources";
        description = "Here are your financing needs.";
        stepStatus = this.getStepStatus(3);

        break;

      case 4:
        redirectTo = scenario?.netPrice
          ? `/plans/${scenario.id}/funding-options`
          : null;
        title = "Your Financing Options";
        description =
          "Here are pricing estimates for your eligible financing options.";
        stepStatus = this.getStepStatus(4);

        break;

      case 5:
        redirectTo = foId
          ? `/plans/${scenario.id}/funding-options/${foId}/next-steps`
          : null;
        title = "Your Report";
        description = "Congratulations! You’ve created a great plan.";
        stepStatus = this.getStepStatus(5);

        break;

      default:
        break;
    }
    return {
      redirectTo: redirectTo,
      title: title,
      description: description,
      stepStatus: stepStatus,
    };
  }

  renderB() {
    const { label, step } = this.props;
    const { redirectTo } = this.state;

    return (
      <Row>
        {redirectTo && <Redirect to={redirectTo} push />}
        <Col xs={12} className="mt-3 mb-3">
          <h2 className="text-center">{label}</h2>

          <div className="StepProgressBar">
            <ul className="text-center list-unstyled">
              <li
                className={this.getStepState(step, 1)}
                onClick={() => this.handleStepSelected(1)}
              />
              <li
                className={this.getStepState(step, 2)}
                onClick={() => this.handleStepSelected(2)}
              />
              <li
                className={this.getStepState(step, 3)}
                onClick={() => this.handleStepSelected(3)}
              />
              <li
                className={this.getStepState(step, 4)}
                onClick={() => this.handleStepSelected(4)}
              />
              <li
                className={this.getStepState(step, 5)}
                onClick={() => this.handleStepSelected(5)}
              />
            </ul>
          </div>
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <Row className="mb-3 ProgressNavs">
        <Col xs={12}>
          {getPartnerId() && this.props.location.pathname !== "/" && (
            <Button
              color="link"
              className="pl-0 btn-title"
              onClick={() => this.props.history.goBack()}
            >
              <span className="material-icons md-18">arrow_back</span>Back
            </Button>
          )}
          <ol className="list-group list-group-horizontal">
            {this.renderStep(1)}
            {this.renderStep(2)}
            {this.renderStep(3)}
            {this.renderStep(4)}
            {this.renderStep(5)}
          </ol>
        </Col>
      </Row>
    );
  }

  renderStep(step) {
    var stepParams = this.getStepParams(step);
    return (
      <li className={"list-group-item " + stepParams.stepStatus}>
        {stepParams.redirectTo ? (
          <Link to={stepParams.redirectTo}>
            <span>{step}.</span> {stepParams.title}
          </Link>
        ) : stepParams.title.length < 28 ? (
          `${step}. ${stepParams.title}`
        ) : (
          `${step}. ${stepParams.title.substring(30, 0) + "..."}`
        )}
      </li>
    );
  }
}

export default withRouter(StepProgressBar);
