import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import {
  FormGroup,
  FormFeedback,
  Label,
  Input,
  UncontrolledPopover,
  PopoverBody,
  FormText
} from "reactstrap";

import { put, get } from "../../../utils/DeApi";
import { getStudentId } from "../../../utils/AuthManager";

import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import Loader from "../../Loader/Loader";

class CreditScore extends Component {
  static propTypes = {
    student: PropTypes.object.isRequired,
  };

  static defaultProps = {
    creditRatingValid: true
  }

  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      creditGroups: props.creditGroups,
      creditRating: props.student.creditRating,
    };
    this.handleCreditRatingChange = this._handleCreditRatingChange.bind(this);
  }

  _handleCreditRatingChange(event) {
    this.setState({ creditRating: event.target.value }, () =>
      this.updateStudent()
    );
  }

  componentDidMount() {
    if (!this.state.creditGroups) this.fetchCreditRanges();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
  }

  updateStudent() {
    const { onStudentUpdated, selectedCreditRating } = this.props;
    const { creditRating, creditGroups } = this.state;

    this.setState({ error: "", isLoading: true });

    let studentPromise = put(
      "/profile",
      getStudentId({ creditRating: creditRating })
    );
    studentPromise.promise
      .then((response) => {
        this.setState({ error: "", isLoading: false }, () => {
          onStudentUpdated(response.data);
          if (selectedCreditRating)
            selectedCreditRating(
              creditGroups.find((group) => {
                return group.value === creditRating;
              })
            );
        });
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(studentPromise);
  }

  fetchCreditRanges() {
    const { selectedCreditRating } = this.props;
    const { creditRating } = this.state;
    var creditGroups = [
      { value: "Excellent", label: "Excellent: 800 – 850" },
      { value: "Very Good", label: "Strong: 750 – 799" },
      { value: "Good", label: "Good: 690 – 749" },
      { value: "Poor", label: "Not So Good: ≦ 689" },
    ];

    this.setState({ error: "", isLoading: true });
    let creditRangesPromise = get("/credit-ratings", {
      params: getStudentId({}),
    });

    creditRangesPromise.promise
      .then((response) => {
        if (response.data?.length) {
          creditGroups = [];
          response.data.forEach((group) => {
            creditGroups.push({
              value: group.name,
              label: group.range || group.name,
            });
          });
        }

        var selected = creditGroups.find((group) => {
          return group.value === creditRating;
        });

        this.setState(
          {
            creditGroups: creditGroups,
            error: "",
            isLoading: false,
          },
          () => {
            if (selectedCreditRating) selectedCreditRating(selected);
          }
        );
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(creditRangesPromise);
  }

  render() {
    const { creditRating, error, isLoading, creditGroups } = this.state;
    const { creditRatingValid } = this.props

    if (isLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;

    if (!creditGroups) return <span />;

    return (
      <Fragment>
        <p className="personalize-question mb-1">
          Give us a ballpark estimate on the credit strength of the parent or
          other co-signer.
          <span className="material-icons md-18 ml-1" id="credit-strength-info">
            info_outline
          </span>
        </p>
        <p className="text-muted">
          A good credit score can also help save money in the long run.
        </p>
        <UncontrolledPopover
          placement="bottom"
          trigger={"hover"}
          target="credit-strength-info"
        >
          <PopoverBody>
            Pricing and eligibility on many financing options is based on credit
            strength. Most Private Student Loans strongly encourage a co-signer
            because students often don’t have a lengthy credit history. Although
            most students typically have a parent co-signer, some students apply
            with another relative or friend of the family. If no co-signer is
            available, select your own credit strength.
          </PopoverBody>
        </UncontrolledPopover>
        {creditGroups.map((rating, index) => {
          return (
            <FormGroup key={index} className="pl-5" check>
              <Label check className="pt-2 pb-2">
                <Input
                  type="radio"
                  name="creditRating"
                  value={rating.value}
                  checked={creditRating === rating.value}
                  onChange={this.handleCreditRatingChange}
                />
                {rating.label}
              </Label>
            </FormGroup>
          );
        })}
        {!creditRatingValid && <small className="text-danger">This is required.</small>}

        {!creditRating && <FormFeedback tooltip>This is required.</FormFeedback>}
      </Fragment>
    );
  }
}

export default CreditScore;
