import React, { Component, Fragment } from "react";
import "./School.css";
import { AsyncTypeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import debounce from "lodash/debounce";

import { get } from "../../utils/DeApi";

class School extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };
    this.handleSchoolSelected = this._handleSchoolSelected.bind(this);
    this.subscribedPromises = [];

    this.handleSearch = debounce(
      (query) => {
        this.fetchSchools(query);
      },
      200,
      { trailing: true, maxWait: 1000 }
    );
  }

  _handleSchoolSelected(selected) {
    this.setState({ selected: selected }, () => {
      this.props.onSchoolSelected(selected[0]);
    });
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
    this.handleSearch.cancel();
  }

  fetchSchools(query) {
    if (!query || query.length <= 2) return;

    this.setState({ error: "", isLoading: true });
    const { partner } = this.props;

    const createPromise = get("/search/schools", {
      params: { search: query || "", partner: partner },
    });

    createPromise.promise
      .then((response) => {
        this.setState({
          schools: response.data || [],
          error: null,
          isLoading: false,
        });
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ isLoading: false, error: error });
      });

    this.subscribedPromises.push(createPromise);
  }

  render() {
    const { selected, isLoading, schools } = this.state;
    const { size, label } = this.props;
    return (
      <Fragment>
        <AsyncTypeahead
          id="school-search-typeahead"
          placeholder={label || "Search for a college..."}
          isLoading={isLoading}
          {...(!size && { bsSize: "large" })}
          onSearch={this.handleSearch}
          options={schools}
          onChange={this.handleSchoolSelected}
          selected={selected}
          labelKey={(option) => `${option.name}`}
          filterBy={() => true}
          useCache={false}
          renderMenu={(results, menuProps) => (
            <Menu {...menuProps}>
              {results.map((result, index) => (
                <MenuItem
                  option={result}
                  position={index}
                  key={index}
                  className="text-capitalize"
                >
                  <p className="mb-1">
                    {result.name}
                    <small className="float-right text-muted">
                      {result.code}
                    </small>
                  </p>
                  {result.aliases.map((item) => (
                    <small
                      key={item.id}
                      className="mr-1 pl-2 pr-2 bg-light text-muted"
                    >
                      {item.name}
                    </small>
                  ))}
                </MenuItem>
              ))}
            </Menu>
          )}
        />
      </Fragment>
    );
  }
}

export default School;
