import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Container, Col, Row, Button, Badge } from "reactstrap";
import isEmpty from "lodash/isEmpty";
import Numeral from "numeral";
import { Link } from "react-router-dom";

import "./Dashboard.scss";
import { get, put, isLoggedIn as isLoggedInDE } from "../../utils/DeApi";
import { getStudentId } from "../../utils/AuthManager";

import Header from "../App/Header/Header";
import Footer from "../App/Footer/Footer";
import Onboarding from "./Onboarding/Onboarding";
import School from "../School/School";
import ScenarioList from "../Scenario/ScenarioList/ScenarioList";
import ScenarioCreate from "../Scenario/ScenarioCreate/ScenarioCreate";
import YearPicker from "../Student/EnrollmentYear/YearPicker/YearPicker";
import Loader from "../Loader/Loader";
import ErrorHandler from "../ErrorHandler/ErrorHandler";
import StepProgressBar from "../Scenario/StepProgressBar/StepProgressBar";
/**
 * Renders the main dashboard for a writer.
 */
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};

    this.handleSchoolSelected = this._handleSchoolSelected.bind(this);
    this.handleEnrollmentYearChange =
      this._handleEnrollmentYearChange.bind(this);
  }

  _handleSchoolSelected(school) {
    this.setState({ selectedSchool: school });
  }

  _handleEnrollmentYearChange(enrollmentYear) {
    const { student } = this.state;
    student.enrollmentYear = enrollmentYear;

    this.setState({ student: student }, () => {
      let studentPromise = put(
        "/profile",
        getStudentId({ enrollmentYear: enrollmentYear })
      );
      studentPromise.promise.then((response) => {}).catch((error) => {});
      this.subscribedPromises.push(studentPromise);
    });
  }

  getReferralPartner() {
    let { location } = this.props;
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get("ref_partner");
  }

  componentDidMount() {
    Numeral.defaultFormat("$0,0");
    if (isLoggedInDE() || !isEmpty(getStudentId())) {
      this.fetchStudent();
      this.fetchCreditRanges();
    }
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
  }

  fetchStudent() {
    this.setState({ isLoading: true, error: "" });
    let studentPromise = get(`/profile`, {
      params: getStudentId({}),
    });
    studentPromise.promise
      .then((response) => {
        this.setState({ student: response.data, error: "", isLoading: false });
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });
    this.subscribedPromises.push(studentPromise);
  }

  fetchCreditRanges() {
    const creditGroups = [
      { value: "Excellent", label: "Excellent: 800 – 850" },
      { value: "Very Good", label: "Strong: 750 – 799" },
      { value: "Good", label: "Good: 690 – 749" },
      { value: "Poor", label: "Not So Good: ≦ 689" },
    ];

    let creditRangesPromise = get("/credit-ratings", {
      params: getStudentId({}),
    });
    creditRangesPromise.promise
      .then((response) => {
        if (response.data?.length) {
          let creditGroups = [];
          response.data.forEach((group) => {
            creditGroups.push({
              value: group.name,
              label: group.range || group.name,
            });
          });
        }
        this.setState({ creditGroups: creditGroups });
      })
      .catch((error) => {
        console.warn(error);
        this.setState({ creditGroups: creditGroups });
      });

    this.subscribedPromises.push(creditRangesPromise);
  }

  render() {
    return (
      <div className="Main">
        <Header title={"Dashboard"} />
        <Container fluid={false} className="Dashboard MainContent">
          {this.renderDashboard()}
        </Container>
        <Footer />
      </div>
    );
  }

  renderDashboard() {
    const { student, isLoading, error } = this.state;

    if (!isLoggedInDE() && isEmpty(getStudentId()))
      return (
        <Onboarding
          referralPartner={this.getReferralPartner()}
          collectsEmail={false}
          hasRegistration={true}
        />
      );

    if (isLoading || !student) return <Loader />;
    if (error) return <ErrorHandler error={error} />;

    return (
      <Fragment>
        <StepProgressBar step={1} scenario={null} />

        <Row className="mb-5">
          <Col xs={12}>{this.renderChooseSchool()}</Col>

          <Col xs={12}>
            <div className="mt-4">
              <ScenarioList student={student} />
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  }

  renderChooseSchool() {
    const { selectedSchool, student } = this.state;

    return (
      <div className="Dashboard-CTA mt-2 mb-3">
        {!isLoggedInDE() && (
          <Fragment>
            <div className="alert alert-primary">
              <p className="mb-2">
                Register to save and access your financing plans and step by
                step instructions whenever you need them.
              </p>
              <p className="mb-0">
                <Button size="sm" color="primary" tag={Link} to="/signup">
                  Create your account
                </Button>{" "}
                <Button size="sm" color="success" tag={Link} to="/login">
                  Have an account? Login
                </Button>
              </p>
            </div>
          </Fragment>
        )}
        <p>
          Your college start year of{" "}
          <YearPicker
            enrollmentYear={student.enrollmentYear}
            onYearChange={this.handleEnrollmentYearChange}
          />
        </p>
        <h1>Search for a college.</h1>
        <p>Look up all your top college choices.</p>
        <School onSchoolSelected={this.handleSchoolSelected} />
        {selectedSchool && <ScenarioCreate school={selectedSchool} />}
      </div>
    );
  }

  renderProfile() {
    const { student, creditGroups } = this.state;
    if (!creditGroups) return <span />;
    const borrowers = {
      parent: student.preferParentPaying,
      student: student.preferStudentPaying,
    };

    var borrowerTypes = [
      { value: "parent", label: "Parent" },
      { value: "student", label: "Student" },
    ];

    var selectedCreditRating = creditGroups.find((group) => {
      return group.value === student.creditRating;
    });

    return (
      <Fragment>
        <div>
          <p className="float-right mb-0">{student.enrollmentYear}</p>
          <p>Enrollment Year</p>
        </div>
        <div>
          <p className="float-right mb-0">{selectedCreditRating.label}</p>
          <p>Credit Score</p>
        </div>
        <div>
          <p className="float-right mb-0">
            {borrowerTypes.map((borrower, index) => {
              return borrowers[borrower.value] ? (
                <Badge className="text-capitalize ml-1" key={index}>
                  {borrower.label}
                </Badge>
              ) : (
                ""
              );
            })}
          </p>
          <p>Borrowers</p>
        </div>
        <div>
          <p className="float-right mb-0">
            {Numeral(student.parentSavings).format()}
          </p>
          <p>Savings</p>
        </div>
        <div>
          <p className="float-right mb-0">
            {Numeral(student.parentMonthlyAffordability).format()}/mo
          </p>
          <p>Cash Contribution</p>
        </div>
        <div className="mt-3 mb-3">
          <Button color="primary" size="sm" tag={Link} to="/account" block>
            Update your profile
          </Button>
        </div>
      </Fragment>
    );
  }
}

Dashboard.propTypes = {
  user: PropTypes.object.isRequired,
};

export default Dashboard;
