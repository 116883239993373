import React, { Component, Fragment } from "react";
import { Route, Switch, Redirect, Link } from "react-router-dom";
import { Container, Col, Row, Badge } from "reactstrap";
import Numeral from "numeral";

import "./Account.scss";

import { get } from "../../utils/BeeApi";
import { get as getDE } from "../../utils/DeApi";

import { isLoggedIn as isLoggedInDE } from "../../utils/DeApi";

import Header from "../App/Header/Header";
import Footer from "../App/Footer/Footer";
import EnrollmentYear from "../Student/EnrollmentYear/EnrollmentYear";
import CreditScore from "../Student/CreditScore/CreditScore";
import Borrower from "../Student/Borrower/Borrower";
import Cash from "../Student/Cash/Cash";
import Savings from "../Student/Savings/Savings";

import Loader from "../Loader/Loader";
import ErrorHandler from "../ErrorHandler/ErrorHandler";

class Scenario extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      breadcrumbs: [
        {
          title: "Profile",
          link: `/account`
        }
      ]
    };

    this.handleStudentUpdated = this._handleStudentUpdated.bind(this);
  }

  _handleStudentUpdated(student) {
    this.setState({ student: student });
  }

  componentDidMount() {
    Numeral.defaultFormat("$0,0");
    this.fetchSubscriber();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchSubscriber() {
    if (!isLoggedInDE()) return;
    let subscriberPromise = get("/user/anon");
    subscriberPromise.promise
      .then(response => {
        this.setState(
          {
            subscriber: response.data,
            error: ""
          },
          () => this.fetchStudent()
        );
      })
      .catch(error => {
        if (!error.isCanceled) {
          this.setState({
            error: error
          });
        }
      });
    this.subscribedPromises.push(subscriberPromise);
  }

  fetchStudent() {
    if (!isLoggedInDE()) return;
    this.setState({
      studentIsLoading: true,
      studentError: ""
    });
    let studentPromise = getDE("/profile");
    studentPromise.promise
      .then(response => {
        this.setState({
          student: response.data,
          studentError: "",
          studentIsLoading: false
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            studentError: error,
            studentIsLoading: false
          });
      });
    this.subscribedPromises.push(studentPromise);
  }

  render() {
    let user = this.props.user || {};
    const { breadcrumbs } = this.state;

    if (!isLoggedInDE()) return <Redirect to="/login" />;

    return (
      <div className="Main">
        <Header
          user={user}
          title="Account"
          location={this.props.location}
          breadcrumbs={breadcrumbs}
        />
        <Container fluid={false} className="Account MainContent">
          <Row className="mb-3">
            <Col xs={12} md={4}>
              {this.renderUser()}
            </Col>
            <Col xs={12} md={8}>
              {this.renderProfile()}
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }

  renderUser() {
    const { subscriber, isLoading, error } = this.state;

    if (isLoading || !subscriber) return <Loader />;
    if (error) return <ErrorHandler error={error} />;

    return (
      <Fragment>
        <Row className="UserProfile mt-3 mb-3">
          <Col xs={3} className="pt-2 pb-2 ProfilePictureDefault">
            <div className="pic">{`${subscriber.firstName.charAt(
              0
            )}${subscriber.lastName.charAt(0)}`}</div>
          </Col>
          <Col xs={9}>
            <h3 className="mt-3 mb-0">{`${subscriber.firstName} ${subscriber.lastName}`}</h3>
            <p>{subscriber.email}</p>
          </Col>
        </Row>
        <hr />
        {
          <ul className="">
            <li>
              <p>
                <Link to="/account">Overview</Link>
              </p>
            </li>
            <li>
              <p>
                <Link to="/account/enrollment-year">Enrollment Year</Link>
              </p>
            </li>
            <li>
              <p>
                <Link to="/account/credit-score">Credit Score</Link>
              </p>
            </li>
            <li>
              <p>
                <Link to="/account/borrowers">Borrowers</Link>
              </p>
            </li>
            <li>
              <p>
                <Link to="/account/savings">Savings</Link>
              </p>
            </li>
            <li>
              <p>
                <Link to="/account/cash">Cash</Link>
              </p>
            </li>
          </ul>
        }
      </Fragment>
    );
  }

  renderProfile() {
    const { student, studentIsLoading, studentError } = this.state;

    if (studentIsLoading || !student) return <Loader />;
    if (studentError) return <ErrorHandler error={studentError} />;

    return (
      <Fragment>
        <Switch>
          <Route exact path="/account" children={this.renderOverview()} />
          <Route
            exact
            path="/account/enrollment-year"
            children={this.renderEnrollmentYear()}
          />
          <Route
            exact
            path="/account/credit-score"
            children={this.renderCreditScore()}
          />
          <Route
            exact
            path="/account/borrowers"
            children={this.renderBorrowers()}
          />
          <Route
            exact
            path="/account/savings"
            children={this.renderSavings()}
          />
          <Route exact path="/account/cash" children={this.renderCash()} />
        </Switch>
      </Fragment>
    );
  }

  renderOverview() {
    const { student } = this.state;

    const borrowers = {
      parent: student.preferParentPaying,
      student: student.preferStudentPaying
    };

    var borrowerTypes = [
      {
        value: "parent",
        label: "Parent"
      },
      {
        value: "student",
        label: "Student"
      }
    ];

    var creditGroups = [
      {
        value: "Excellent",
        label: "Excellent: 800 – 850"
      },
      {
        value: "Very Good",
        label: "Strong: 750 – 799"
      },
      {
        value: "Good",
        label: "Good: 690 – 749"
      },
      {
        value: "Poor",
        label: "Not So Good: ≦ 689"
      }
    ];

    var selectedCreditRating = creditGroups.find(group => {
      return group.value === student.creditRating;
    });

    return (
      <div className="card mt-3 mb-3">
        <div className="card-body">
          <h3>Overview</h3>
          <hr />
          <div>
            <p className="float-right">{student.enrollmentYear}</p>
            <p>
              <Link to="/account/enrollment-year">Enrollment Year</Link>
            </p>

            <p>
              The first fall semester the student would be starting in college.
            </p>
          </div>
          <hr />

          <div>
            <p className="float-right">{selectedCreditRating.label}</p>
            <p>
              <Link to="/account/credit-score">Credit Score</Link>
            </p>
            <p>A good credit score can also help save money in the long run.</p>
          </div>
          <hr />

          <div>
            <p className="float-right">
              {borrowerTypes.map((borrower, index) => {
                return borrowers[borrower.value] ? (
                  <Badge className="text-capitalize ml-1" key={index}>
                    {borrower.label}
                  </Badge>
                ) : (
                  ""
                );
              })}
            </p>
            <p>
              <Link to="/account/borrowers">Borrower</Link>
            </p>
            <p>
              Borrower is the person taking out a student loan and responsible
              for repayment, usually with interest.
            </p>
          </div>
          <hr />

          <div>
            <p className="float-right">
              {Numeral(student.parentSavings).format()}
            </p>
            <p>
              <Link to="/account/savings">Savings</Link>
            </p>
            <p>
              Savings from 529 plans and other accounts can be used to help pay
              college costs for the academic year (typically done in
              installments through a tuition payment plan).
            </p>
          </div>
          <hr />
          <div>
            <p className="float-right">
              {Numeral(student.parentMonthlyAffordability).format()}/mo
            </p>
            <p>
              <Link to="/account/cash">Cash Contribution</Link>
            </p>
            <p>
              Cash payments can be used to cover tuition payments and other
              expenses like housing. These payments can really help reduce
              long-term borrowing costs.
            </p>
          </div>
        </div>
      </div>
    );
  }

  renderEnrollmentYear() {
    const { student } = this.state;

    return (
      <div className="card mt-3 mb-3">
        <div className="card-body">
          <h3>Enrollment Year</h3>
          <hr />
          <EnrollmentYear
            student={student}
            onStudentUpdated={student => this.handleStudentUpdated(student)}
          />
        </div>
      </div>
    );
  }

  renderCreditScore() {
    const { student } = this.state;

    return (
      <div className="card mt-3 mb-3">
        <div className="card-body">
          <h3>Credit Score</h3>
          <hr />
          <CreditScore
            student={student}
            onStudentUpdated={student => this.handleStudentUpdated(student)}
          />
        </div>
      </div>
    );
  }

  renderBorrowers() {
    const { student } = this.state;

    return (
      <div className="card mt-3 mb-3">
        <div className="card-body">
          <h3>Borrowers</h3>
          <hr />
          <Borrower
            student={student}
            onStudentUpdated={student => this.handleStudentUpdated(student)}
          />
        </div>
      </div>
    );
  }

  renderCash() {
    const { student } = this.state;

    return (
      <div className="card mt-3 mb-3">
        <div className="card-body">
          <h3>Cash Contribution</h3>
          <hr />
          <Cash
            student={student}
            onStudentUpdated={student => this.handleStudentUpdated(student)}
          />
        </div>
      </div>
    );
  }

  renderSavings() {
    const { student } = this.state;

    return (
      <div className="card mt-3 mb-3">
        <div className="card-body">
          <h3>Savings</h3>
          <hr />
          <Savings
            student={student}
            onStudentUpdated={student => this.handleStudentUpdated(student)}
          />
        </div>
      </div>
    );
  }
}

export default Scenario;
