const YEARLY_MONTH_COUNT = 12;
const YEARS_IN_SCHOOL = 4;
const LOAN_INTEREST = 1.05;

function getNetPrice(netPrice) {
  return netPrice * LOAN_INTEREST;
}

function getDeficit(annualNetPrice, parentMonthlyAffordability) {
  let annualAffordability = YEARLY_MONTH_COUNT * parentMonthlyAffordability;
  let deficit = annualNetPrice - annualAffordability;
  return deficit < 0 ? 0 : deficit;
}

function getAnnualContribution(
  parentSaving,
  parentAffordability,
  schoolYears = YEARS_IN_SCHOOL
) {
  parentAffordability = parentAffordability || 0;
  parentSaving = parentSaving || 0;

  let annualMonthlyAffordabilty = parentAffordability * YEARLY_MONTH_COUNT;
  let annualSavingAffordabilty = parentSaving / schoolYears;

  return annualMonthlyAffordabilty + annualSavingAffordabilty;
}

function getAnnualizedCashContribution(parentAffordability) {
  parentAffordability = parentAffordability || 0;
  let annualMonthlyAffordabilty = parentAffordability * YEARLY_MONTH_COUNT;
  return annualMonthlyAffordabilty;
}

function getAnnualizedSavingsContribution(
  parentSaving,
  schoolYears = YEARS_IN_SCHOOL
) {
  parentSaving = parentSaving || 0;
  let annualSavingAffordabilty = parentSaving / schoolYears;
  return annualSavingAffordabilty;
}

function getSchoolMonths(schoolYears = YEARS_IN_SCHOOL) {
  return schoolYears * YEARLY_MONTH_COUNT;
}

function getMonthlyContribution(
  parentSaving,
  parentAffordability,
  schoolYears = YEARS_IN_SCHOOL
) {
  parentAffordability = parentAffordability || 0;
  parentSaving = parentSaving || 0;

  let monthlySavingAffordabilty = parentSaving / getSchoolMonths(schoolYears);

  return parentAffordability + monthlySavingAffordabilty;
}

export {
  getAnnualContribution,
  getMonthlyContribution,
  getAnnualizedCashContribution,
  getAnnualizedSavingsContribution,
  getDeficit,
  getNetPrice,
  getSchoolMonths,
};
