import React, { Component } from "react";
import "./Footer.scss";
import { Container, Row, Col } from "reactstrap";
import { get } from "../../../utils/BeeApi";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      content: {}
    };
  }

  componentDidMount() {
    this.fetchContent();
  }

  fetchContent() {
    let subscriberPromise = get("/contents/5ec2eb77bb5d2");
    subscriberPromise.promise
      .then(response => {
        this.setState({
          content: response.data,
          error: ""
        });
      })
      .catch(error => {
        if (!error.isCanceled) {
          this.setState({
            error: error
          });
        }
      });
    this.subscribedPromises.push(subscriberPromise);
  }

  render() {
    const { content } = this.state;
    return (
      <div className="MainFooter">
        <Container fluid={false} className="MainFooterContent mt-3 mb-3">
          <Row>
            <Col xs={12}>
              <ul className="float-right list-inline">
                <li className="list-inline-item">&copy; Quatromoney { (new Date()).getFullYear() }</li>
              </ul>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <a href="https://www.quatromoney.com">About</a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.quatromoney.com/contact">Contact</a>
                </li>
              </ul>
            </Col>
            <Col xs={12}>
              <div
                className="body-section mt-md mb-md"
                dangerouslySetInnerHTML={{ __html: content.contentBody }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Footer;
