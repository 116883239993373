import { getTotalOfPayments, getHighestPostGradPayment } from "./FinancingOptionCalculators";
import { isNull } from "lodash";

function getShortTermCVF(school) {
  return school.graduationRate || null;
}

function getMidTermCVF(financingOption, scenario) {
  var midTerm = null;
  var totalOfPayments = getTotalOfPayments(financingOption.loans);
  if (!totalOfPayments) midTerm = 100;
  else if (!scenario.school.estimatedFirstYearEarnings) {
	midTerm = null;
  } else {
		var studentLoans = financingOption.loans.filter(
			(loan) => loan.borrower === "student"
		);
		if (studentLoans.length) {
			var studentAfterGradPayments = getHighestPostGradPayment(studentLoans);
			var monthlyEarnings = scenario.school.estimatedFirstYearEarnings / 12;
			var paymentsToEarnings =
				(studentAfterGradPayments / monthlyEarnings) * 100;

			var bucket = [100];
			for (let index = 1; index <= 20; index++) {
				bucket.push(bucket[index - 1] - 1.5);
			}
			for (let index = 21; index <= 30; index++) {
				bucket.push(bucket[index - 1] - 2);
			}
			for (let index = 31; index <= 50; index++) {
				bucket.push(bucket[index - 1] - 2.5);
			}
			for (let index = 51; index <= 70; index++) {
				bucket.push(bucket[index - 1] - 5);
			}
			for (let index = 71; index <= 100; index++) {
				bucket.push(-100);
			}

			if (paymentsToEarnings <= 0) midTerm = 100;
			else if (paymentsToEarnings <= 70)
				midTerm = Math.round(bucket[Math.round(paymentsToEarnings)]);
			else midTerm = -100;
		} else {
			var firstYearEarnings =
          scenario.school.estimatedFirstYearEarnings || null;
        var firstYearEarningsAverage = 55260;
        midTerm = firstYearEarnings
          ? (firstYearEarnings / firstYearEarningsAverage) * 100
          : null;
		}
  }

	return midTerm;
}

function getLongTermCVF(school) {
	return school.careerEarningsWeight || null;
}

function calculateActualCVF(shortTerm, midTerm, longTerm) {
	var CVF = 0;

	if (!isNull(shortTerm) && !isNull(midTerm) && !isNull(longTerm)) {
		CVF = 0.25 * shortTerm + 0.5 * midTerm + 0.25 * longTerm;
	} else if (!isNull(shortTerm) && !isNull(midTerm) && isNull(longTerm)) {
		CVF = 0.5 * shortTerm + 0.5 * midTerm;
	} else if (!isNull(shortTerm) && isNull(midTerm) && !isNull(longTerm)) {
		CVF = 0.5 * shortTerm + 0.5 * longTerm;
	} else if (isNull(shortTerm) && !isNull(midTerm) && !isNull(longTerm)) {
		CVF = 0.5 * midTerm + 0.5 * longTerm;
	} else {
		CVF = shortTerm || midTerm || longTerm;
	}

	CVF = CVF < 0 ? 0 : (CVF > 100 ? 100 : CVF);

	return CVF;
}

export { getShortTermCVF, getMidTermCVF, getLongTermCVF, calculateActualCVF };