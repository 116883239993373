import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";

import {
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";

import { put, post } from "../../../utils/DeApi";

import { getStudentId, setStudentId } from "../../../utils/AuthManager";
import { isEmail } from "../../../utils/StringUtils";

import School from "../../School/School";
import ScenarioCreate from "../../Scenario/ScenarioCreate/ScenarioCreate";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import YearPicker from "../../Student/EnrollmentYear/YearPicker/YearPicker";
import Loader from "../../Loader/Loader";
import Disclosure from "../../Widget/Disclosure/Disclosure";
import OnboardingHelp from "./OnboardingHelp";
class Onboarding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailValid: !props.collectsEmail,
      nameValid: false,
      keepMeUpdated: true,
      enrollmentYear: this.getDefaultStartYear(),
    };

    this.subscribedPromises = [];
    this.handleEmailChange = this._handleEmailChange.bind(this);
    this.handleEnrollmentYearChange =
      this._handleEnrollmentYearChange.bind(this);
    this.handleStudentUpdated = this._handleStudentUpdated.bind(this);
    this.handleSchoolSelected = this._handleSchoolSelected.bind(this);
    this.handleScenarioCreated = this._handleScenarioCreated.bind(this);
  }

  _handleEmailChange(event) {
    let email = event.target.value;
    this.setState({ email: email, emailValid: isEmail(email) });
  }

  _handleEnrollmentYearChange(enrollmentYear) {
    this.setState({ enrollmentYear: enrollmentYear });
  }

  _handleStudentUpdated(student) {
    this.setState({ student: student });
  }

  _handleSchoolSelected(school) {
    this.setState({ selectedSchool: school });
  }

  _handleScenarioCreated() {
    const { email } = this.state;
    if (!email || !this.props.hasRegistration) return;
    let upadateMarketingPromise = put(
      "/profile/marketing",
      getStudentId({ email: email })
    );
    upadateMarketingPromise.promise.then((response) => {}).catch((error) => {});
    this.subscribedPromises.push(upadateMarketingPromise);
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
  }

  startPlanning() {
    this.createStudent();
  }

  createStudent() {
    const { partner, referralPartner, partnerInstance } = this.props;
    const { enrollmentYear, email, emailValid, selectedSchool } = this.state;
    this.setState({ formSubmitted: true });
    if (!emailValid || !selectedSchool) return;
    this.setState({ isLoading: true, error: "" });

    let createPromise = post(`/profile`, {
      enrollmentYear: enrollmentYear,
      ...(referralPartner && { referralPartner: referralPartner }),
      ...(partner && { partnerId: partner }),
      ...(email && { planEmail: email }),
      ...(partnerInstance && { widgetInstance: partnerInstance }),
    });

    createPromise.promise
      .then((response) => {
        let student = response.data;
        setStudentId(student.id);
        this.setState({
          student: student,
          startPlanning: true,
          error: "",
          isLoading: false,
        });
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });
    this.subscribedPromises.push(createPromise);
  }

  getDefaultStartYear() {
    let currentYear = moment();
    if (currentYear.month() > 7) currentYear.add(1, "years");
    return currentYear.format("YYYY");
  }

  render() {
    const {
      error,
      isLoading,
      email,
      emailValid,
      selectedSchool,
      startPlanning,
      formSubmitted,
      enrollmentYear,
    } = this.state;

    const { disclosure, hasRegistration, collectsEmail, partner } = this.props;

    if (isLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;
    if (startPlanning)
      return (
        <ScenarioCreate
          school={selectedSchool}
          onScenarioCreated={this.handleScenarioCreated}
        />
      );

    return (
      <Row className="Onboarding mb-5">
        <OnboardingHelp ctaContent={this.props.ctaContent} />
        <Col xs={12} md={5}>
          <Card>
            <CardBody>
              <div
                className={`${
                  !selectedSchool && formSubmitted
                    ? "is-invalid pt-2 pb-2"
                    : "pt-2 pb-2"
                }`}
              >
                <h1>Search for a college.</h1>
                <p className="mb-1">
                  <small>Look up all your top college choices.</small>
                </p>
                <School
                  onSchoolSelected={this.handleSchoolSelected}
                  size={{ bsSize: "small" }}
                  label={"Type college name..."}
                  partner={partner}
                />
                {!selectedSchool && formSubmitted && (
                  <div className="invalid-feedback">
                    Please type college name to select a college.
                  </div>
                )}
              </div>
              <div className="mt-3 mb-3">
                <p className="mb-2">What will be the first year in college?</p>
                <YearPicker
                  enrollmentYear={enrollmentYear}
                  onYearChange={this.handleEnrollmentYearChange}
                />
              </div>

              {collectsEmail && (
                <FormGroup className="mt-3 mb-3 pt-2 pb-2">
                  <Label htmlFor="signupEmail">
                    Enter an email address to receive college finance plans and
                    other financing insights.
                  </Label>
                  <Input
                    type="email"
                    id="signupEmail"
                    value={email}
                    onChange={this.handleEmailChange}
                    valid={emailValid}
                    invalid={!emailValid && formSubmitted}
                    placeholder="Email"
                  />
                  <FormFeedback>
                    This should be a valid email address.
                  </FormFeedback>
                </FormGroup>
              )}

              <div className="text-center pt-3 pb-3">
                <Button
                  type="submit"
                  color="primary"
                  onClick={() => this.startPlanning()}
                  block
                >
                  Start
                </Button>
                {hasRegistration && (
                  <Button color="link" tag={Link} to="/login" block>
                    Have an account? Login
                  </Button>
                )}
              </div>
            </CardBody>
          </Card>
          <div className="text-muted text-center">
            {disclosure ? (
              <small>
                <Disclosure content={disclosure} />
              </small>
            ) : (
              <small>
                By continuing, you agree to Quatromoney{" "}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.quatromoney.com/terms-of-use"
                >
                  Terms of Service
                </a>{" "}
                and have read and acknowledge Quatromoney{" "}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.quatromoney.com/privacy-policy"
                >
                  Privacy Statement
                </a>
                .
              </small>
            )}
          </div>
        </Col>
      </Row>
    );
  }
}

Onboarding.propTypes = {
  referralPartner: PropTypes.string,
  collectsEmail: PropTypes.bool,
  hasRegistration: PropTypes.bool,
};
export default Onboarding;
