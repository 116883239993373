import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { FormGroup, Label, Input } from "reactstrap";

import { put } from "../../../utils/DeApi";
import { getStudentId } from "../../../utils/AuthManager";

import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import Loader from "../../Loader/Loader";

class Borrower extends Component {
  static propTypes = {
    student: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      borrowers: {
        parent: props.student.preferParentPaying,
        student: props.student.preferStudentPaying
      }
    };
    this.handleBorrowerChange = this._handleBorrowerChange.bind(this);
  }

  _handleBorrowerChange(event) {
    const { borrowers } = this.state;
    borrowers[event.target.name] = event.target.checked;
    if (!borrowers.parent && !borrowers.student) {
      borrowers[event.target.name] = !borrowers[event.target.name];
      return this.setState({
        borrowers: borrowers
      });
    } else
      this.setState(
        prevState => {
          return {
            borrowers: borrowers
          };
        },
        () => this.updateStudent()
      );
  }

  updateStudent() {
    const { borrowers } = this.state;
    const { onStudentUpdated } = this.props;

    this.setState({ error: "", isLoading: true });
    let studentPromise = put(
      "/profile",
      getStudentId({
        preferStudentPaying: borrowers.student,
        preferParentPaying: borrowers.parent
      })
    );

    studentPromise.promise
      .then(response => {
        this.setState({ error: "", isLoading: false }, () =>
          onStudentUpdated(response.data)
        );
      })
      .catch(error => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(studentPromise);
  }

  render() {
    const { borrowers, isLoading, error } = this.state;

    if (isLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;

    var borrowerTypes = [
      {
        value: "parent",
        label: "Parent"
      },
      {
        value: "student",
        label: "Student"
      }
    ];

    return (
      <Fragment>
        <p className="personalize-question mb-1">
          Who will be borrowing to cover the college costs?
        </p>
        <p className="text-muted">
          You must choose at least one option to continue.
        </p>
        {borrowerTypes.map((borrower, index) => {
          return (
            <FormGroup key={index} className="pl-5" check>
              <Label check className="pt-2 pb-2">
                <Input
                  type="checkbox"
                  name={borrower.value}
                  value={borrower.value}
                  disabled={isLoading}
                  checked={borrowers[borrower.value]}
                  onChange={this.handleBorrowerChange}
                />
                {borrower.label}
              </Label>
            </FormGroup>
          );
        })}
      </Fragment>
    );
  }
}

export default Borrower;
