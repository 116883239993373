import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  Alert,
  Container,
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  FormFeedback,
  FormText
} from "reactstrap";

import "./Signup.css";
import { isLoggedIn, login, post } from "../../../utils/BeeApi";
import {
  isLoggedIn as isLoggedInDE,
  put,
  login as loginDE
} from "../../../utils/DeApi";
import { isPassword, isEmail } from "../../../utils/StringUtils";
import { deleteStudentId, studentId } from "../../../utils/AuthManager";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class Signup extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleFirstNameChange = this._handleFirstNameChange.bind(this);
    this.handleLastNameChange = this._handleLastNameChange.bind(this);
    this.handleEmailChange = this._handleEmailChange.bind(this);
    this.handlePasswordChange = this._handlePasswordChange.bind(this);

    this.handleLogin = this._handleLogin.bind(this);
    this.updateProfile = this._updateProfile.bind(this);
    this.togglePopover = this._togglePopover.bind(this);

    const loggedIn = isLoggedInDE() && isLoggedIn();
    let email = loggedIn ? loggedIn.email : "";

    this.state = {
      email: email,
      password: "",
      emailValid: !!email,
      passwordValid: false,
      firstNameValid: false,
      firstName: "",
      lastNameValid: false,
      lastName: "",
      formError: "",
      loggedIn: loggedIn,
      success: false,
      popoverOpen: false
    };

    this.subscribedPromises = [];
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  _togglePopover() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  _handleFirstNameChange(event) {
    let firstName = event.target.value;
    this.setState({
      firstName: firstName,
      firstNameValid: firstName.length > 2 ? true : false
    });
  }

  _handleLastNameChange(event) {
    let lastName = event.target.value;
    this.setState({
      lastName: lastName,
      lastNameValid: lastName.length > 2 ? true : false
    });
  }

  _handleEmailChange(event) {
    let email = event.target.value;
    this.setState({
      email: email,
      emailValid: isEmail(email)
    });
  }

  _handlePasswordChange(event) {
    let password = event.target.value;
    this.setState({
      password: password,
      passwordValid: isPassword(password)
    });
  }

  _handleSubmit(event) {
    event.preventDefault();
    this.setState({ formSubmitted: true });

    const {
      emailValid,
      password,
      passwordValid,
      firstName,
      lastName,
      email
    } = this.state;
    if (emailValid && passwordValid) {
      this.setState({
        error: "",
        isLoading: true
      });

      const createPromise = post("/user", {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        password_confirmation: password
      });

      createPromise.promise
        .then(response => {
          this.handleLogin();
        })
        .catch(error => {
          this.setState({
            error: error,
            isLoading: false
          });
        });

      this.subscribedPromises.push(createPromise);
    }
  }

  _handleLogin() {
    const { email, password } = this.state;

    return login({
      email: email,
      password: password
    })
      .then(data => {
        return loginDE();
      })
      .then(data => {
        if (studentId()) this.updateProfile();
        else {
          this.setState({ error: false, isLoading: false, success: true });
        }
      })
      .catch(error => {
        this.setState({
          error: error,
          isLoading: false
        });
      });
  }

  _updateProfile() {
    let profilePromise = put(`/profile/consumer`, {
      studentId: studentId()
    });
    profilePromise.promise
      .then(response => {
        this.setState(
          {
            error: false,
            isLoading: false,
            success: true
          },
          () => {
            deleteStudentId();
          }
        );
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error,
            isLoading: false
          });
      });
    this.subscribedPromises.push(profilePromise);
  }

  renderRegistrationForm() {
    const {
      error,
      firstName,
      firstNameValid,
      lastName,
      lastNameValid,
      email,
      emailValid,
      password,
      passwordValid,
      formSubmitted
    } = this.state;
    return (
      <Form onSubmit={this.handleSubmit}>
        <h2 className="text-center">Create an account</h2>
        <hr />
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label htmlFor="signupFirstName">First Name</Label>
              <Input
                type="text"
                id="signupFirstName"
                value={firstName}
                onChange={this.handleFirstNameChange}
                valid={firstNameValid}
                invalid={!firstNameValid && formSubmitted}
                placeholder="Enter first name"
                autoFocus
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label htmlFor="signupLastName">Last Name</Label>
              <Input
                type="text"
                id="signupLastName"
                value={lastName}
                onChange={this.handleLastNameChange}
                valid={lastNameValid}
                invalid={!lastNameValid && formSubmitted}
                placeholder="Enter last name"
              />
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup>
              <Label htmlFor="signupEmail">Email Address</Label>
              <Input
                type="email"
                id="signupEmail"
                value={email}
                onChange={this.handleEmailChange}
                valid={emailValid}
                invalid={!emailValid && formSubmitted}
                placeholder="Enter email"
              />
              <FormFeedback>This should be a valid email address</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup>
              <Label htmlFor="signupPassword">Password</Label>
              <Input
                id="signupPassword"
                type="password"
                value={password}
                onChange={this.handlePasswordChange}
                valid={passwordValid}
                invalid={!passwordValid && formSubmitted}
                placeholder="Password"
              />
              <FormText>
                Password must contain at least 6 characters, including
                UPPER/lowercase and numbers.
              </FormText>
            </FormGroup>
          </Col>

          {error &&
            (error => {
              if (error.status === 409) {
                return (
                  <Alert color="warning" className="mt-md mb-md">
                    An account associated with this email address already
                    exists. Please use a different email address.
                  </Alert>
                );
              } else {
                return <ErrorHandler error={error} />;
              }
            })(error)}

          <Button type="submit" color="primary" size="lg" block>
            Create Account
          </Button>
          <Col md={12}>
            <p className="text-center pt-3">
              <Link to="/login">Already have an account?</Link>
            </p>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    const { isLoading, loggedIn, success } = this.state;
    let { from } = this.props.location.state || { from: { pathname: "/" } };
    if (loggedIn || success) return <Redirect to={from} />;
    if (isLoading) return <Loader />;

    return (
      <Container fluid className="Login mt-5">
        <Row>
          <Col sm="12" md={{ size: 6, offset: 3 }}>
            <div className="text-center">
              <Link to="/">
                <img
                  className="img-fluid mb-4"
                  width="300"
                  src="/quatro-logo.png"
                  alt="logo"
                />
              </Link>
            </div>
            <Card className="mt-2">
              <CardBody>{this.renderRegistrationForm()}</CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Signup;
