import React, { Component, Suspense, lazy } from "react";
import "./Scenario.css";
import { Route, Switch } from "react-router-dom";

import Header from "../App/Header/Header";
import Footer from "../App/Footer/Footer";
import Loader from '../Loader/Loader';
// import ScenarioPricing from "./ScenarioPricing/ScenarioPricing";
// import FundingGap from "./FundingGap/FundingGap";
// import FundingOptions from "./FundingOptions/FundingOptions";
// import MonthlyDetails from "./MonthlyDetails/MonthlyDetails";
// import NextSteps from "./NextSteps/NextSteps";
// import FundingOptionDetails from "./FundingOptionDetails/FundingOptionDetails";
// import CollegeValue from "./CollegeValue/CollegeValue";

const ScenarioPricing = lazy(() => import('./ScenarioPricing/ScenarioPricing'));
const FundingGap = lazy(() => import('./FundingGap/FundingGap'));
const FundingOptions = lazy(() => import('./FundingOptions/FundingOptions'));
const MonthlyDetails = lazy(() => import('./MonthlyDetails/MonthlyDetails'));
const NextSteps = lazy(() => import('./NextSteps/NextSteps'));
const FundingOptionDetails = lazy(() => import('./FundingOptionDetails/FundingOptionDetails'));
const CollegeValue = lazy(() => import('./CollegeValue/CollegeValue'));

/**
 * Handles Scenario level routing.
 */
class Scenario extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};

    this.handleUpdateBreadcrumb = this._handleUpdateBreadcrumb.bind(this);
  }

  _handleUpdateBreadcrumb(scenario, fundingOption) {
    this.updateBreadcrumb(scenario, fundingOption);
  }

  render() {
    const { user } = this.props;
    const { breadcrumbs } = this.state;
    return (
      <div className="Main">
        <Header
          user={user || {}}
          title=""
          location={this.props.location}
          breadcrumbs={breadcrumbs}
        />
        <div className="MainContent Scenario">
				<Suspense fallback={<Loader />}>
						<Switch>
							<Route
								exact
								path="/plans/:scenarioId"
								render={(props) => (
									<ScenarioPricing
										scenarioId={props.match.params.scenarioId}
										updateBreadcrumb={this.handleUpdateBreadcrumb}
									/>
								)}
							/>
							<Route
								exact
								path="/plans/:scenarioId/funding-gap"
								render={(props) => (
									<FundingGap
										scenarioId={props.match.params.scenarioId}
										updateBreadcrumb={this.handleUpdateBreadcrumb}
									/>
								)}
							/>
							<Route
								exact
								path="/plans/:scenarioId/funding-options"
								render={(props) => (
									<FundingOptions
										scenarioId={props.match.params.scenarioId}
										updateBreadcrumb={this.handleUpdateBreadcrumb}
									/>
								)}
							/>
							<Route
								exact
								path="/plans/:scenarioId/funding-options/:packageId"
								render={(props) => (
									<FundingOptionDetails
										packageId={props.match.params.packageId}
										scenarioId={props.match.params.scenarioId}
										updateBreadcrumb={this.handleUpdateBreadcrumb}
									/>
								)}
							/>
							<Route
								exact
								path="/plans/:scenarioId/funding-options/:packageId/repayment-examples"
								render={(props) => (
									<MonthlyDetails
										packageId={props.match.params.packageId}
										scenarioId={props.match.params.scenarioId}
										updateBreadcrumb={this.handleUpdateBreadcrumb}
									/>
								)}
							/>
							<Route
								exact
								path="/plans/:scenarioId/funding-options/:packageId/next-steps"
								render={(props) => (
									<NextSteps
										packageId={props.match.params.packageId}
										scenarioId={props.match.params.scenarioId}
										updateBreadcrumb={this.handleUpdateBreadcrumb}
									/>
								)}
							/>
							<Route
								exact
								path="/plans/:scenarioId/funding-options/:packageId/college-value"
								render={(props) => (
									<CollegeValue
										packageId={props.match.params.packageId}
										scenarioId={props.match.params.scenarioId}
										updateBreadcrumb={this.handleUpdateBreadcrumb}
									/>
								)}
							/>
						</Switch>
					</Suspense>
        </div>
        <Footer />
      </div>
    );
  }

  updateBreadcrumb(scenario, fundingOption) {
    const { location } = this.props;

    if (!scenario || !location) return "";

    let fragments = location.pathname.split("?")[0].split("/") || [];

    fragments.shift();

    switch (fragments.length) {
      case 1:
        break;
      case 2:
        this.setBreadcrumbs(scenario, fundingOption, 0);
        break;
      case 3:
        if (fragments[2] === "funding-gap")
          this.setBreadcrumbs(scenario, fundingOption, 1);
        else this.setBreadcrumbs(scenario, fundingOption, 2);
        break;
      case 4:
        this.setBreadcrumbs(scenario, fundingOption, 3);

        break;
      case 5:
        if (fragments[4] === "next-steps")
          this.setBreadcrumbs(scenario, fundingOption, 4, "b");
        else this.setBreadcrumbs(scenario, fundingOption, 4, "a");
        break;
      default:
        break;
    }
  }

  setBreadcrumbs(scenario, fundingOption = {}, level = 0, subLevel = null) {
    const breadcrumbsMap = [
      {
        title: scenario.school.name,
        link: `/plans/${scenario.id}`,
      },
      {
        title: "Financing Needs",
        link: `/plans/${scenario.id}/funding-gap`,
      },
      {
        title: "Financing Options",
        link: `/plans/${scenario.id}/funding-options`,
      },
      {
        title: fundingOption.name || "Financing Elements",
        link: `/plans/${scenario.id}/funding-options/${fundingOption.id}`,
      },
    ];

    if (subLevel === "a")
      breadcrumbsMap.push({
        title: "Repayment Examples",
        link: `/plans/${scenario.id}/funding-options/${fundingOption.id}/repayment-examples`,
      });
    else
      breadcrumbsMap.push({
        title: "Next Steps",
        link: `/plans/${scenario.id}/funding-options/${fundingOption.id}/next-steps`,
      });

    let breadcrumbs = [];
    for (var i = 0; i <= level; i++) {
      breadcrumbs.push(breadcrumbsMap[i]);
    }
    this.setState({ breadcrumbs: breadcrumbs });
  }
}

export default Scenario;
