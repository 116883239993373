import React, { Component } from "react";
import "./ScenarioCreate.css";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { Card, Button, CardBody, CardTitle, CardSubtitle } from "reactstrap";

import { post } from "../../../utils/DeApi";
import { getStudentId, setStudentId } from "../../../utils/AuthManager";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class ScenarioCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleCreateScenario = this._handleCreateScenario.bind(this);
    this.subscribedPromises = [];
  }

  componentDidMount() {
    this.handleCreateScenario();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  _handleCreateScenario() {
    const { school, onScenarioCreated } = this.props;

    if (Object.entries(school).length !== 0 && school.constructor === Object) {
      this.setState({ isLoading: true });

      const createPromise = post(
        "/scenarios",
        getStudentId({
          schoolId: school.id
        })
      );

      createPromise.promise
        .then(response => {
          let data = response.data;
          setStudentId(data.student.id);
          return new Promise(resolve =>
            setTimeout(() => resolve(response), 300)
          );
        })
        .then(response => {
          this.setState(
            {
              scenario: response.data,
              error: "",
              isLoading: false
            },
            () => {
              onScenarioCreated && onScenarioCreated();
            }
          );
        })
        .catch(error => {
          !error.isCanceled &&
            this.setState({
              error: error,
              isLoading: false
            });
        });
      this.subscribedPromises.push(createPromise);
    }
  }

  render() {
    const { school } = this.props;
    const { scenario, isLoading, error } = this.state;

    if (isLoading) return <Loader />;
    if (scenario) return <Redirect push to={`/plans/${scenario.id}`} />;

    return (
      <Card className="mt-3 mb-3">
        <CardBody>
          <CardTitle>{school.name}</CardTitle>
          <CardSubtitle className="mb-3">
            <small className="text-muted">{school.code}</small>
          </CardSubtitle>
          {error && <ErrorHandler error={error} />}
          {error && (
            <div>
              <Button
                className="float-right"
                outline
                color="primary"
                onClick={this.handleCreateScenario}
              >
                Retry
              </Button>
            </div>
          )}
        </CardBody>
      </Card>
    );
  }
}

ScenarioCreate.defaultProps = {
  school: {}
};

ScenarioCreate.propTypes = {
  school: PropTypes.object.isRequired
};

export default ScenarioCreate;
