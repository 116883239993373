import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody
} from "reactstrap";

import "./Login.css";
import { isLoggedIn, login } from "../../../utils/BeeApi";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

import {
  deleteStudentId,
  clearStudentOnboarded
} from "../../../utils/AuthManager";

import {
  isLoggedIn as isLoggedInDE,
  login as loginDE
} from "../../../utils/DeApi";

/**
 * Handles logging in to the application.
 * Handles single sign on.
 */
class Login extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleEmailChange = this._handleEmailChange.bind(this);
    this.handlePasswordChange = this._handlePasswordChange.bind(this);

    const loggedIn = isLoggedIn() && isLoggedInDE();
    var email = loggedIn ? loggedIn.email : "";
    this.state = {
      email: email,
      password: "",
      emailValid: !!email || false,
      passwordValid: false,
      error: "",
      loggedIn: loggedIn,
      formSubmitted: false
    };
  }

  _handleEmailChange(event) {
    let email = event.target.value;
    this.setState({
      email: email,
      emailValid: email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        ? true
        : false
    });
  }

  _handlePasswordChange(event) {
    let password = event.target.value;
    this.setState({
      password: password,
      passwordValid: password.length ? true : false
    });
  }

  _handleSubmit(event) {
    event.preventDefault();
    this.setState(
      {
        formSubmitted: true
      },
      (state, props) => {
        if (this.state.emailValid && this.state.passwordValid) {
          this.setState({
            error: "",
            isLoading: true
          });
          login({
            email: this.state.email,
            password: this.state.password
          })
            .then(data => {
              return loginDE();
            })
            .then(data => {
              this.setState(
                {
                  loggedIn: true,
                  isLoading: false
                },
                () => {
                  deleteStudentId();
                  clearStudentOnboarded();
                }
              );
            })
            .catch(error => {
              this.setState({
                error: error,
                isLoading: false
              });
            });
        }
      }
    );
  }

  renderLoginForm() {
    const {
      email,
      emailValid,
      password,
      passwordValid,
      error,
      formSubmitted
    } = this.state;
    return (
      <Form onSubmit={this.handleSubmit}>
        <h2 className="text-center">Login to continue</h2>
        <hr />
        <FormGroup>
          <Label htmlFor="loginEmail">Email Address</Label>
          <Input
            type="email"
            id="loginEmail"
            value={email}
            onChange={this.handleEmailChange}
            valid={emailValid}
            invalid={!emailValid && formSubmitted}
            placeholder="Enter email"
            autoFocus
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="loginEmail">Password</Label>
          <Input
            id="loginPassword"
            label="Password"
            type="password"
            value={password}
            onChange={this.handlePasswordChange}
            valid={passwordValid}
            invalid={!passwordValid && formSubmitted}
            placeholder="Password"
          />
        </FormGroup>

        {error && <ErrorHandler error={error} />}
        <Button type="submit" color="primary" size="lg" block>
          Login
        </Button>
        <p className="text-muted text-center pt-3 pb-1">
          <small>
            By continuing, you agree to our{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.quatromoney.com/terms-of-use"
            >
              Terms of Service
            </a>{" "}
            and have read and acknowledge our{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.quatromoney.com/privacy-policy"
            >
              Privacy Statement
            </a>
            .
          </small>
        </p>
        <hr />
        <p className="text-center">
          Not registered? <Link to="/signup">Create account</Link>
        </p>
        <p className="text-center">
          <Link to="/login-help">Forgot Password?</Link>
        </p>
      </Form>
    );
  }

  render() {
    const { loggedIn, isLoading } = this.state;

    if (loggedIn) {
      let { from } = this.props.location.state || {
        from: { pathname: "/" }
      };
      return <Redirect to={from} />;
    }

    if (isLoading) return <Loader />;

    return (
      <Container fluid className="Login mt-5">
        <Row>
          <Col sm="12" md={{ size: 4, offset: 4 }} lg={{ size: 4, offset: 4 }}>
            <div className="text-center">
              <Link to="/">
                <img
                  className="img-fluid mb-4"
                  width="300"
                  src="/quatro-logo.png"
                  alt="logo"
                />
              </Link>
            </div>
            <Card className="mt-2">
              <CardBody>{this.renderLoginForm()}</CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Login;
