import React, { Component, Fragment } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

import { get } from "../../../utils/BeeApi";

class Disclosure extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};
    this.bodyRef = React.createRef();

    this.toggle = this._onToggleModal.bind(this);
  }

  _onToggleModal() {
    this.setState({ show: !this.state.show });
  }

  componentDidMount() {
    this.getContent();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
  }

  getContent() {
    const contentId = this.props.content || "6011a062ccbc3";
    this.setState({ error: "", isLoading: true });
    const contentPromise = get(`contents/${contentId}`, {});
    contentPromise.promise
      .then((response) => {
        this.setState({ content: response.data, error: "", isLoading: false });
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });
    this.subscribedPromises.push(contentPromise);
  }

  updateLinkTargets() {
    if (this.bodyRef.current) {
      let aTags = this.bodyRef.current.getElementsByTagName("a");
      for (let aTag of aTags) {
        aTag.setAttribute("target", "_blank");
        aTag.setAttribute("rel", "noopener noreferrer");
      }
    }
  }

  render() {
    const { show, error, content, isLoading } = this.state;
    const { label } = this.props;

    if (isLoading) return <span>Loading...</span>;
    if (error) return <span />;
    if (!content) return <div />;

    return (
      <Fragment>
        <Button color="link" onClick={this.toggle} size="sm">
          {label || content.description || "Disclosures"}
        </Button>
        <Modal
          isOpen={show}
          toggle={this.toggle}
          backdrop={"static"}
          container={".MainContent"}
          size="lg"
          onOpened={() => this.updateLinkTargets()}
        >
          <ModalHeader tag="div" toggle={this.toggle}>
            <h4>{content.description || "Disclosures"}</h4>
          </ModalHeader>
          <ModalBody>
            <div
              dangerouslySetInnerHTML={{ __html: content.contentBody }}
              ref={this.bodyRef}
              className="Content"
            />
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

export default Disclosure;
