import React, { Component, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  Link
} from "react-router-dom";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";

import "./App.css";
import { isLoggedIn } from "../../utils/BeeApi";
import { isLoggedIn as isLoggedInDE } from "../../utils/DeApi";
import { tokenExist } from "../../utils/AuthManager";
import withTracker from "../../HOC/withTracker";
import Login from "./Login/Login";
import Logout from "./Logout/Logout";
import Signup from "./Signup/Signup";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import ResetPassword from "./ResetPassword/ResetPassword";
import Dashboard from "../Dashboard/Dashboard";
import Scenario from "../Scenario/Scenario";
import Account from "../Account/Account";

/**
 * @advancedMatching optional
 * moreInfo https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
 */
const advancedMatching = {};
const options = {
  autoConfig: true,
  debug: true
};

ReactPixel.init("1379997702152765", advancedMatching, options);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let hostname = window.location.hostname;
    ReactPixel.track("ViewContent", hostname);
  }

  render() {
    return (
      <Router>
        <ScrollToTop />

        <Switch>
          <PrivateRoute exact path="/" component={Dashboard} />
          <PrivateRoute path="/plans/" component={Scenario} />
          <PrivateRoute path="/account/" component={Account} />

          <PrivateRoute path="/logout" component={Logout} />
          <Route path="/login" component={withTracker(Login, {})} />
          <Route path="/signup" component={withTracker(Signup, {})} />
          <Route
            path="/login-help"
            component={withTracker(ForgotPassword, {})}
          />
          <Route
            path="/reset-password"
            component={withTracker(ResetPassword, {})}
          />
          <Route component={withTracker(NoMatch, {})} />
        </Switch>
      </Router>
    );
  }
}

/**
 * Middleware for private routes.
 */
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      window.scrollTo(0, 0);
      let user = isLoggedIn();
      let path = props.location.pathname;

      ReactGA.pageview(path);

      if (invalidLoginTokens(path)) {
        return <Redirect to="/logout" />;
      } else {
        return <Component {...props} user={user || {}} />;
      }
    }}
  />
);

function invalidLoginTokens(path) {
  return (
    (!isLoggedIn() || !isLoggedInDE()) && tokenExist() && path !== "/logout"
  );
}

/**
 * 404: No match route
 */
const NoMatch = ({ location }) => (
  <div className="text-center pt-5 pb-5">
    <p>Oops! We couldn't find this page!</p>
    <code>
      404
      <br />
      <small>No match for {location.pathname}</small>
    </code>

    <h3 className="mt-3 mb-3">
      <Link to={"/"}>
        Go to Dashboard<span className="material-icons">arrow_forward</span>
      </Link>
    </h3>
  </div>
);

// Scroll To Top on Navigation
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default App;
