import React from "react";
import { Spinner } from "reactstrap";
import "./Loader.css";

let Loader = props => {
  let className = props.className ? props.className : "Loader pt-5 pb-5";
  return (
    <div className={className}>
      <Spinner color="primary" />
    </div>
  );
};

export default Loader;
