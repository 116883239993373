export function getPartnerId() {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get("partner_id");
}

export function getPartnerHost() {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get("partner_host");
}

export function getPartnerInstance() {
  const queryParams = new URLSearchParams(window.location.search);
  var instance =
    queryParams.get("partner_instance") || queryParams.get("widget_id");
  if (instance && instance !== "undefined") return instance;
  return "";
}

function getPartnerDisclosures() {
  const queryParams = new URLSearchParams(window.location.search);
  let disclosures = queryParams.get("disclosures");
  if (disclosures && disclosures !== "undefined") return disclosures;
  return "6011a062ccbc3";
}

function shouldShowMarketLoans() {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get("market_loans") === "show";
}

function shouldCollectEmail() {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get("collect_email") === "yes";
}

function getNextStepsCategory() {
  const queryParams = new URLSearchParams(window.location.search);
  let partnerNextSteps = queryParams.get("next_steps");
  if (partnerNextSteps && partnerNextSteps !== "undefined")
    return partnerNextSteps;
  return null;
}

function shouldShowHeloc() {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get("heloc_loans") === "show";
}

function getPartnerCTAContent() {
  const queryParams = new URLSearchParams(window.location.search);
  let ctaContent = queryParams.get("cta_content");
  if (ctaContent && ctaContent !== "undefined") return ctaContent;
  return "609a8a573c291";
}

function getPartnerPDFContent() {
  const queryParams = new URLSearchParams(window.location.search);
  let pdfContent = queryParams.get("pdf_content");
  if (pdfContent && pdfContent !== "undefined") return pdfContent;
  return null;
}

function getPartnerTheme() {
  const queryParams = new URLSearchParams(window.location.search);
  let theme = {};
  if (queryParams.get("theme")) {
    try {
      theme = JSON.parse(queryParams.get("theme"));
    } catch (e) {
      console.warn("Error in widget theme configuration");
    }
  }
  return theme;
}

function shouldRemoveCopyright() {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get("remove_copyright") === "true";
}

function shouldRemoveQuestions() {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get("remove_questions") === "true";
}

export function getWidgetConfigurations() {
  return {
    name: getPartnerInstance(),
    id: getPartnerInstance(),
    partner: {
      id: getPartnerId(),
      name: null,
      hosts: [getPartnerHost()],
    },
    configurations: {
      theme: getPartnerTheme(),
      nextSteps: getNextStepsCategory(),
      ctaContent: getPartnerCTAContent(),
      disclosures: getPartnerDisclosures(),
      pdfPrefix: null,
      pdfAppendix: getPartnerPDFContent(),
      collectsEmails: shouldCollectEmail(),
      showHELOCLoans: shouldShowHeloc(),
      showMarketLoans: shouldShowMarketLoans(),
			removeQuestions: shouldRemoveQuestions(),
			removeCopyright: shouldRemoveCopyright()
    },
  };
}
