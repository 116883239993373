import React from "react";
import "./CollegeValueSummary.scss";

import ValueGaugeSummary from "../ValueGaugeSummary/ValueGaugeSummary";

import { Link } from "react-router-dom";
import {
	getShortTermCVF,
	getMidTermCVF,
	getLongTermCVF,
	calculateActualCVF
} from "../../../../enumerator/CollegeValueFactor";
import { isNull } from "lodash";

export function getCollegeValueFactor(scenario, financingOption) {

	var shortTerm = getShortTermCVF(scenario.school);
	let midTerm = getMidTermCVF(financingOption, scenario);
	var longTerm = getLongTermCVF(scenario.school)
	var CVF = calculateActualCVF(shortTerm, midTerm, longTerm);

	return CVF;
}

export function CollegeValueSummary(props) {
  const { scenario, financingOption } = props;
  var CVF = getCollegeValueFactor(scenario, financingOption);

  var color = CVF > 66.66 ? "green" : CVF > 33.33 ? "yellow" : "red";

  return (
    <div className="CollegeValueSummary">
			{isNull(CVF) ?
			(
				<big>N/A</big>
			) :
			(
				<div>
					<div className={`CVFScore bg-${color}`}></div> {"  "}
					<big>{parseInt(CVF)}</big>
				</div>
			)}

      <p className="mb-0">
        <Link
          to={`/plans/${scenario.id}/funding-options/${scenario.preferredLoanId}/college-value`}
        >
          <small>
            Learn more
            <i className="material-icons md-12">arrow_forward</i>
          </small>
        </Link>
      </p>{" "}
    </div>
  );

  // var sentiment =
  //   CVF > 66.66 ? (
  //     <span className="material-icons md-32">sentiment_satisfied_alt</span>
  //   ) : CVF > 33.33 ? (
  //     <span className="material-icons md-32">sentiment_satisfied</span>
  //   ) : (
  //     <span className="material-icons-outlined md-32">sentiment_neutral</span>
  //   );
  // return (
  //   <div className="CollegeValueSummary">
  //     <span className={`CVFSentiment bg-${color} float-right`}>
  //       {sentiment}
  //     </span>
  //     <big>{parseInt(CVF)}</big>{" "}
  //     <p className="mb-0">
  //       <Link
  //         to={`/plans/${scenario.id}/funding-options/${scenario.preferredLoanId}/college-value`}
  //       >
  //         <small>
  //           Learn more
  //           <i className="material-icons md-12">arrow_forward</i>
  //         </small>
  //       </Link>
  //     </p>
  //   </div>
  // );
}

export function CollegeValueSummaryExpanded(props) {
  const { scenario, financingOption, onChartRendered } = props;
  var CVF = getCollegeValueFactor(scenario, financingOption);


  return (
    <div className="CollegeValueSummary">
      <ValueGaugeSummary
        data={[
          {
            value: !isNaN(CVF) ? CVF / 100 : 'N/A',
            name: "CollegeValueFactor",
          },
        ]}
				onFinishedEvent={(src) => {onChartRendered && onChartRendered(src)}}
      />
    </div>
  );
}
