import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Numeral from "numeral";
import remove from "lodash/remove";
import { Row, Col } from "reactstrap";

import ScenarioDelete from "../ScenarioDelete/ScenarioDelete";
import { CollegeValueSummary } from "../CollegeValue/CollegeValueSummary/CollegeValueSummary";

class ScenarioSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.subscribedPromises = [];
    this.handleDeleteScenario = this._handleDeleteScenario.bind(this);
  }

  _handleDeleteScenario(scenarioId) {
    let scenarios = [...this.state.scenarios];
    remove(scenarios || [], (scenario) => {
      return scenario.id === scenarioId;
    });
    this.setState({
      scenarios: scenarios || [],
    });
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.subscribedPromises.forEach((promise) => {
      promise.cancel();
    });
  }

  render() {
    const { scenario, handleDelete } = this.props;

    if (!scenario) return <span />;
    return (
      <Row className="mt-1 mb-1">
        <Col xs={12}>
          <span className="float-right">
            <ScenarioDelete
              handleDelete={handleDelete}
              scenarioId={scenario.id}
            />
          </span>
          <p className="mb-1">
            <Link to={`/plans/${scenario.id}`}>{scenario.school.name}</Link>{" "}
            <small className=" text-muted ml-1">{scenario.school.code}</small>
          </p>
          <p className="mb-1">{scenario.school.sector}</p>
        </Col>

        <Col xs={12} sm={4} md={4} className="mt-0 mb-3">
          {this.renderTotalOfCost()}
        </Col>
        <Col xs={12} sm={4} md={4} className="mt-0 mb-3">
          {this.renderTotalOfPayments()}
        </Col>
        <Col xs={12} sm={4} md={4} className="mt-0 mb-3">
          {this.renderCollegeValueFactor()}
        </Col>
      </Row>
    );
  }

  renderTotalOfCost() {
    const { scenario } = this.props;
    return (
      <Fragment>
        <p className="text-muted  mb-0">
          <small>Your Financing Need</small>
        </p>
        {scenario.fundingGap !== null && (
          <p className="mb-0">
            <big>{Numeral(scenario.fundingGap).format("$0,0")}</big>
          </p>
        )}

        <p className="mb-0">
          <small>
            <Link to={`/plans/${scenario.id}`}>
              Review your cost
              <i className="material-icons md-12">arrow_forward</i>
            </Link>
          </small>
        </p>
      </Fragment>
    );
  }

  renderTotalOfPayments() {
    const { scenario } = this.props;
    return (
      <Fragment>
        <p className="text-muted  mb-0">
          <small>Your Financing Plan</small>
        </p>

        {typeof scenario.estimatedFinancing !== "undefined" && (
          <p className="mb-0 ">
            <big>{Numeral(scenario.estimatedFinancing).format("$0,0")}</big>
          </p>
        )}
        <p className="mb-0">
          <small>
            {scenario.netPrice === null ? (
              <Link to={`/plans/${scenario.id}`}>
                Review your cost
                <i className="material-icons md-12">arrow_forward</i>
              </Link>
            ) : typeof scenario.estimatedFinancing === "undefined" ? (
              <Link
                disabled={scenario.netPrice <= 0}
                to={`/plans/${scenario.id}/funding-options`}
              >
                Select a financing option{" "}
                <i className="material-icons md-12">arrow_forward</i>
              </Link>
            ) : (
              <Link
                disabled={scenario.netPrice <= 0}
                to={`/plans/${scenario.id}/funding-options/${scenario.preferredLoanId}/next-steps`}
              >
                Review your plan{" "}
                <i className="material-icons md-12">arrow_forward</i>
              </Link>
            )}
          </small>
        </p>
      </Fragment>
    );
  }

  renderCollegeValueFactor() {
    const { scenario } = this.props;
    return (
      <Fragment>
        <p className="text-muted  mb-0">
          <small>Your College Value Factor™</small>
        </p>
        {scenario.financingOption ? (
          <CollegeValueSummary
            scenario={scenario}
            financingOption={scenario.financingOption}
          />
        ) : (
          <p className="mb-0 text-muted">
            <small>
              {scenario.netPrice === null ? (
                <Link to={`/plans/${scenario.id}`}>
                  Review your cost
                  <i className="material-icons md-12">arrow_forward</i>
                </Link>
              ) : typeof scenario.estimatedFinancing === "undefined" ? (
                <Link
                  disabled={scenario.netPrice <= 0}
                  to={`/plans/${scenario.id}/funding-options`}
                >
                  Select a financing option{" "}
                  <i className="material-icons md-12">arrow_forward</i>
                </Link>
              ) : (
                <Link
                  disabled={scenario.netPrice <= 0}
                  to={`/plans/${scenario.id}/funding-options`}
                >
                  Select a financing option{" "}
                  <i className="material-icons md-12">arrow_forward</i>
                </Link>
              )}
            </small>
          </p>
        )}
      </Fragment>
    );
  }
}

export default ScenarioSummary;
