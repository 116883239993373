import axios from "axios";
import {
  setToken,
  readToken,
  getToken,
  clearToken,
  validateToken
} from "./AuthService";
import { MakeCancelable } from "./CancelablePromise";

/**
 * Handles API access to DE.
 * Builds http requests via axios.
 * Adds Authorization headers and http errors interceptors.
 * Converts http requests to cancelable promises to allow cancellation of promises for unmounted components.
 */

const TOKEN_NAME = "de-token";
// const END_POINT = "https://quatromoney-de.test/api/v1";
const END_POINT = "https://p4e-de.pixeledge.io/api/v1";

const queryParams = new URLSearchParams(window.location.search);
let widgetId = queryParams.get("widget_id") || null;

var instance = axios.create({
  baseURL: END_POINT,
	transformRequest: [
    data => {
      return data;
    },
    ...axios.defaults.transformRequest
  ]
});

registerRequestInterceptor(instance);
registerResponseInterceptor(instance);


function registerRequestInterceptor(instance) {
	instance.interceptors.request.use(
	function (config) {
		const xsrfToken = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
		const httpOnlyConfig = { "XSRF-TOKEN": xsrfToken };
		let headers = {
			Authorization: (config.url === '/login' && config.method === 'post') ?
				`Bearer ${getToken("bee-token")}` :
				`Bearer ${getToken(TOKEN_NAME)}`
		}

		// If widgetId is found, then this is a widget request hence append widgetId to the headers
		if (widgetId)
			headers = { ...headers, "widgetID": widgetId }

		// Append the xsrf-token to the data and headers in the case of a post/put/delete request.
		if (['put', 'post', 'delete'].includes(config.method)) {
			if (config.data instanceof FormData) {
        config.data.append("XSRF-TOKEN", xsrfToken)
      } else {
			  config.data = { ...config.data, ...httpOnlyConfig }
      }

      headers = { ...headers, ...httpOnlyConfig }
		}

		// Append the necessary headers.
		config.headers = { ...config.headers, ...headers }

		return config;
	},
	function (error) {
    return Promise.reject(error);
  });
}

// Add a response interceptor for error handling
function registerResponseInterceptor(instance) {
  instance.interceptors.response.use(
    function(response) {
      // Success
      return Promise.resolve(response.data);
    },
    function(error) {
      // Do something with response error
      // @TODO --reauth
      if (error.response) {
        // The request was made and the server responded with a status code
        return Promise.reject({
          data: error.response.data,
          status: error.response.status,
          statusText: error.response.statusText
        });
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        return Promise.reject({
          data: "",
          status: 0,
          statusText: "No Response"
        });
      } else {
        // Something happened in setting up the request that triggered an Error
        return Promise.reject({
          data: "",
          status: 0,
          statusText: error.message
        });
      }
    }
  );
}

function isLoggedIn() {
  if (validateToken(TOKEN_NAME)) return readToken(TOKEN_NAME);
  else return false;
}

function login(data) {
  return instance.post("/login", { ...data })
		.then(response => {
			setToken(TOKEN_NAME, response.data && response.data.token);
			instance = axios.create({ baseURL: END_POINT });

			registerRequestInterceptor(instance);
			registerResponseInterceptor(instance);

    	return Promise.resolve({ data: readToken(TOKEN_NAME) });
  	}
	);
}

function logout() {
  clearToken(TOKEN_NAME);
  instance = axios.create({ baseURL: END_POINT });

  registerRequestInterceptor(instance);
	registerResponseInterceptor(instance);

  return Promise.resolve({ data: { message: "success" } });
}

function get(url, data) {
  return MakeCancelable(instance.get(url, data));
}

function post(url, data) {
  return MakeCancelable(instance.post(url, data));
}

function put(url, data) {
  return MakeCancelable(instance.put(url, data));
}

function destroy(url, data) {
  return MakeCancelable(instance.delete(url, data));
}

function download(url, data) {
  return MakeCancelable(
    instance.get(
      url,
      Object.assign(
        {
          responseType: "blob",
          contentType: "application/octet-stream"
        },
        data
      )
    )
  );
}

export { isLoggedIn, login, logout, post, get, put, destroy, download };
