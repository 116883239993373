import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Button,
  Alert,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  FormFeedback
} from "reactstrap";

import "./ForgotPassword.css";
import { isLoggedIn, put } from "../../../utils/BeeApi";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

import { isLoggedIn as isLoggedInDE } from "../../../utils/DeApi";
import { isEmail } from "../../../utils/StringUtils";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.subscribedPromises = [];
    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleEmailChange = this._handleEmailChange.bind(this);

    const loggedIn = isLoggedInDE() && isLoggedIn();
    let email = loggedIn ? loggedIn.email : "";

    this.state = {
      email: email,
      emailValid: !!email || "",
      error: "",
      loggedIn: loggedIn,
      success: false
    };

    this.subscribedPromises = [];
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  _handleEmailChange(event) {
    let email = event.target.value;
    this.setState({
      email: email,
      emailValid: isEmail(email)
    });
  }

  _handleSubmit(event) {
    event.preventDefault();
    if (this.state.emailValid) {
      this.setState({
        error: "",
        isLoading: true
      });

      const createPromise = put("/login", {
        action: "forgot",
        email: this.state.email
      });
      createPromise.promise
        .then(response => {
          this.setState({
            isLoading: false,
            success: true,
            data: response
          });
        })
        .catch(error => {
          this.setState({
            error: error,
            isLoading: false
          });
        });

      this.subscribedPromises.push(createPromise);
    }
  }

  renderForgotPasswordForm() {
    const { email, emailValid, success, error } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        <h2 className="text-center">Request New Password</h2>
        <p className="text-center">
          We&#x27;ll email you instructions to set your password.
        </p>
        <hr />
        <FormGroup>
          <Label htmlFor="loginEmail">Email Address</Label>
          <Input
            type="email"
            id="loginEmail"
            value={email}
            onChange={this.handleEmailChange}
            valid={emailValid || emailValid !== ""}
            placeholder="Enter email"
            invalid={!emailValid && emailValid !== ""}
            autoFocus
          />
          <FormFeedback>This should be a valid email address</FormFeedback>
        </FormGroup>
        {error && <ErrorHandler error={error} />}
        {success && (
          <Alert color="success">
            <h4>Success!</h4>
            <p>
              Please follow the instructions sent to the email you provided to
              reset your password.
            </p>
          </Alert>
        )}
        <Button className="mb-4" type="submit" color="primary" size="lg" block>
          Send Instructions
        </Button>
        {/* <hr /> */}
        <p className="text-center">
          <Link to="/login">Cancel</Link>
        </p>
      </form>
    );
  }

  render() {
    const { loggedIn, isLoading } = this.state;
    if (loggedIn) {
      let { from } = this.props.location.state || {
        from: { pathname: "/" }
      };
      return <Redirect to={from} />;
    }

    if (isLoading) return <Loader />;

    return (
      <Container fluid className="ForgotPassword mt-5">
        <Row>
          <Col sm="12" md={{ size: 4, offset: 4 }}>
            <div className="text-center">
              <Link to="/">
                <img
                  className="img-fluid mb-4"
                  width="300"
                  src="/quatro-logo.png"
                  alt="logo"
                />
              </Link>
            </div>
            <Card className="mt-2">
              <CardBody>{this.renderForgotPasswordForm()}</CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ForgotPassword;
