import React, { Component, Fragment } from "react";
import "./Feedback.scss";
import {
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  NavItem,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Alert
} from "reactstrap";
import { post } from "../../../utils/BeeApi";

import { isEmail } from "../../../utils/StringUtils";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class Feedback extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];

    this.state = {
      show: false,
      body: "",
      name: this.props.user.firstName || "",
      email: this.props.user.email || ""
    };

    this.handleToggleModal = this._handleToggleModal.bind(this);
    this.handleCloseModal = this._handleCloseModal.bind(this);
    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleFeedbackChange = this._handleFeedbackChange.bind(this);
    this.handleNameChange = this._handleNameChange.bind(this);
    this.handleEmailChange = this._handleEmailChange.bind(this);
  }

  _handleCloseModal() {
    this.setState(
      {
        success: false,
        body: "",
        isLoading: false,
        error: ""
      },
      () => this.handleToggleModal()
    );
  }

  _handleToggleModal() {
    this.setState(prevState => {
      return { show: !prevState.show };
    });
  }

  _handleFeedbackChange(event) {
    let feedback = event.target.value;
    this.setState({ body: feedback, bodyValid: !feedback });
  }

  _handleNameChange(event) {
    let name = event.target.value;
    this.setState({
      name: name
    });
  }

  _handleEmailChange(event) {
    let email = event.target.value;
    this.setState({
      email: email
    });
  }

  _handleSubmit(event) {
    event.preventDefault();
    const { body, email, name } = this.state;

    this.setState({
      error: "",
      formSubmitted: true,
      isLoading: true
    });

    if (!body || !isEmail(email)) {
      this.setState({
        isLoading: false
      });
      return;
    }

    let message = `${body} \r\n \r\n Page URL: ${window.location.href}`;
    let subject = `Feedback - QuatroMoney`;
    const contactPromise = post("/forms", {
      name: name || "",
      email: email,
      subject: subject,
      body: message
    });

    contactPromise.promise
      .then(data => {
        this.setState({
          success: true,
          isLoading: false,
          formSubmitted: false
        });
      })
      .catch(error => {
        this.setState({
          error: error,
          isLoading: false,
          formSubmitted: false
        });
      });
  }

  render() {
    const {
      body,
      name,
      email,
      formSubmitted,
      show,
      error,
      isLoading,
      success
    } = this.state;

    return (
      <Fragment>
        <NavItem className="Feedback" onClick={this.handleToggleModal}>
          <Button outline color="tertiary" size="sm" className="nav-btn mr-3">
            <i className="material-icons">comment</i> Feedback?
          </Button>
        </NavItem>
        <Modal
          isOpen={show}
          toggle={this.handleToggleModal}
          fade={true}
          backdrop="static"
        >
          <ModalHeader toggle={this.handleToggleModal} tag="div">
            <h3 className="mt-0 mb-0">Help us improve!</h3>
          </ModalHeader>
          {success ? (
            <Fragment>
              <ModalBody>
                <Alert color="success" className="mt-md mb-md">
                  <h4>Thanks</h4>
                  <p>
                    We appreciate that you’ve taken the time to send us
                    feedback.
                  </p>
                </Alert>
              </ModalBody>
              <ModalFooter>
                <Button outline color="primary" onClick={this.handleCloseModal}>
                  Close
                </Button>
              </ModalFooter>
            </Fragment>
          ) : (
            <Form
              className="FeedbackForm"
              onSubmit={this.handleSubmit}
              autoComplete="off"
            >
              <ModalBody>
                <Fragment>
                  {!this.props.user.email && (
                    <Fragment>
                      <FormGroup>
                        <Label htmlFor="Name">First Name</Label>
                        <Input
                          type="text"
                          id="name"
                          value={name}
                          onChange={this.handleNameChange}
                          placeholder="Enter name"
                          autoFocus
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label htmlFor="email">Email Address</Label>
                        <Input
                          type="email"
                          id="email"
                          value={email}
                          onChange={this.handleEmailChange}
                          invalid={formSubmitted && !isEmail(email)}
                          placeholder="Enter email"
                        />
                        <FormFeedback>
                          This should be a valid email address
                        </FormFeedback>
                      </FormGroup>
                    </Fragment>
                  )}
                  <FormGroup>
                    <Label htmlFor="message">
                      Do you have a question, comment, or suggestion? Let us
                      know.
                    </Label>
                    <Input
                      id="body"
                      type="textarea"
                      rows="5"
                      onChange={this.handleFeedbackChange}
                      value={body}
                      disabled={isLoading}
                      invalid={formSubmitted && !body}
                      placeholder="Describe your experience here..."
                      required
                    />
                    <FormFeedback>Please enter your message.</FormFeedback>
                  </FormGroup>
                  {error && <ErrorHandler error={error} />}
                  {isLoading && <Loader error={isLoading} />}
                </Fragment>
              </ModalBody>
              <ModalFooter>
                <Button color="link" onClick={this.handleToggleModal}>
                  Cancel
                </Button>
                <Button
                  color="success"
                  type="submit"
                  onClick={this.handleSubmit}
                >
                  Send Feedback
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Modal>
      </Fragment>
    );
  }
}

export default Feedback;
