import React, { Component } from "react";
import "./YearPicker.scss";
import moment from "moment";

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Numeral from "numeral";

class YearPicker extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      enrollmentYear: props.enrollmentYear || this.getDefaultStartYear(),
      yearRange: this.getYearRange(),
    };

    this.toggleYearRange = this._toggleYearRange.bind(this);
    this.getFormattedCollegeYear = this._getFormattedCollegeYear.bind(this);
  }

  _toggleYearRange() {
    this.setState((prevState) => {
      return { yearRangeCollapse: !prevState.yearRangeCollapse };
    });
  }

  getDefaultStartYear() {
    let currentYear = moment();
    if (currentYear.month() > 7) currentYear.add(1, "years");
    return currentYear.format("YYYY");
  }

  getYearRange() {
    let yearRangeStart = moment().year(2019);
    let yearRangeEnd = moment().add(19, "years");
    let yearRange = [yearRangeStart.format("YYYY")];
    while (yearRangeStart.isBefore(yearRangeEnd)) {
      yearRange.push(yearRangeStart.add(1, "years").format("YYYY"));
    }
    return yearRange;
  }

  _getFormattedCollegeYear(year) {
    const currentYear = Number.parseInt(year);
    const nextYear = currentYear + 1;
    const nextYearYY = Numeral(nextYear % 100).format("00");

    return `${year}-${nextYearYY}`;
  }

  render() {
    const { yearRangeCollapse, yearRange } = this.state;
    const { enrollmentYear, onYearChange } = this.props;

    return (
      <span className="YearPicker">
        <Dropdown
          tag="span"
          isOpen={yearRangeCollapse}
          toggle={this.toggleYearRange}
        >
          <DropdownToggle color="link">
            <span>{this.getFormattedCollegeYear(enrollmentYear)}</span>
            <span className="material-icons">arrow_drop_down</span>{" "}
          </DropdownToggle>
          <DropdownMenu tag="span" flip={false}>
            {yearRange.map((year) => {
              return (
                <DropdownItem
                  tag="li"
                  key={year}
                  className={
                    Number(year) === Number(enrollmentYear) ? "active" : ""
                  }
                  onClick={() => onYearChange(year)}
                >
                  {this.getFormattedCollegeYear(year)}
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </Dropdown>
      </span>
    );
  }
}

export default YearPicker;
