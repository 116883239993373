import React, { Component, Fragment } from "react";
import "./Breadcrumber.scss";
import { Link } from "react-router-dom";

import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Helmet } from "react-helmet";

class Breadcrumper extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { breadcrumbs, onToggle } = this.props;

    if (!breadcrumbs || !breadcrumbs.length) return <span />;

    let pageTitles = [breadcrumbs[0].title];
    if (breadcrumbs.length > 1)
      pageTitles.push(breadcrumbs[breadcrumbs.length - 1].title);

    return (
      <Fragment>
        <Breadcrumb>
          <BreadcrumbItem
            className="text-capitalize nav-item"
            tag={Link}
            to="/"
            onClick={onToggle}
          >
            Dashboard
          </BreadcrumbItem>
          {breadcrumbs.map((item, index) => {
            let title =
              item.title.length < 21
                ? item.title
                : item.title.substring(18, 0) + "...";

            let attributes = {
              tag: Link,
              to: item.link,
              key: index,
            };
            return (
              <Fragment key={index}>
                <BreadcrumbItem
                  className="text-capitalize nav-item"
                  {...attributes}
                  onClick={onToggle}
                >
                  {title}
                </BreadcrumbItem>
              </Fragment>
            );
          })}
        </Breadcrumb>
        <Helmet>
          <title>
            {pageTitles.join(" / ").replace(/\w\S*/g, function (txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            })}
          </title>
        </Helmet>
      </Fragment>
    );
  }
}

export default Breadcrumper;
