import {
  setToken,
  getToken,
  validateToken,
  clearToken,
  readToken
} from "./AuthService";

import {
  getPartnerId,
  getPartnerInstance
} from "../enumerator/WidgetConfigurations";
import { isEmpty, isNull } from "lodash";
import { isBase64 } from "./StringUtils";

const partnerId = () =>
  getPartnerId() ? getPartnerId() + "_" + getPartnerInstance() : "";

const STUDENT_ID = `${partnerId() || ""}_studentId`;
const DE_TOKEN_NAME = "de-token";
const BEE_TOKEN_NAME = "bee-token";
const ONBOARDING_ID = `${partnerId() || ""}_personalize-onboarding-status`;

export function setStudentId(id) {
	let encodedId = btoa(id);
  if (!validateToken(DE_TOKEN_NAME)) setToken(STUDENT_ID, encodedId);
}

function fetchStudentId() {
	let id = getToken(STUDENT_ID);

	if (isNull(id) || isEmpty(id)) {
		return id
	}

	return isBase64(id) ? atob(id) : id;
}

export function getStudentId(item = {}) {
  let studentId = fetchStudentId()
  let student = studentId ? { studentId: studentId } : {};
  if (validateToken(DE_TOKEN_NAME)) {
    return item;
  } else {
    return { ...item, ...student };
  }
}

export function getStudentEmail() {
  const subscriber = readToken(BEE_TOKEN_NAME);
  return subscriber.email;
}

export function studentId() {
  return fetchStudentId();
}

export function deleteStudentId() {
  clearToken(STUDENT_ID);
}

export function tokenExist() {
  return !!getToken(DE_TOKEN_NAME) && !!getToken(BEE_TOKEN_NAME);
}

export function getStudentOnboarded() {
  return getToken(ONBOARDING_ID);
}

export function setStudentOnboarded(status) {
  return setToken(ONBOARDING_ID, status);
}

export function clearStudentOnboarded(status) {
  return clearToken(ONBOARDING_ID);
}
