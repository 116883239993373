import React, { useState, useEffect } from "react";
import { Col, Collapse } from "reactstrap";
import { get } from "../../../utils/BeeApi";
import Loader from "../../Loader/Loader";
import PropTypes from "prop-types";

export default function OnboardingHelp(props) {
  const [subscribedPromises, setSubscribedPromises] = useState([]);
  const [ctaContent, setCtaContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [collapaseCTA, setCollapseCTA] = useState(false);

  const fetchCTA = () => {
    const contentId = props.ctaContent || "609a8a573c291";
    const contentPromise = get(`contents/${contentId}`, {});
    setIsLoading(true);
    contentPromise.promise
      .then((response) => {
        setCtaContent(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.warn(error);
        setIsLoading(false);
      });
    setSubscribedPromises([...subscribedPromises, contentPromise]);
  };

  useEffect(() => {
    fetchCTA();
    return () => {
      // clean up all subscribed promises
      subscribedPromises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, []);

  if (isLoading)
    return (
      <Col>
        <Loader />
      </Col>
    );

  return (
    <>
      <Col xs={12} className="Onboarding-CTA mt-5 mb-3">
        <h1>
          {ctaContent
            ? ctaContent.title
            : "Let's help you find the best way to finance a college education."}
        </h1>
      </Col>
      <Col xs={12} md={7}>
        <div className="Onboarding-CTA mb-3">
          <h2
            onClick={() => {
              setCollapseCTA(!collapaseCTA);
            }}
          >
            {ctaContent
              ? ctaContent.description
              : "Here's how it works. 5 steps in under 5 minutes."}
            <i className="material-icons md-36 responsivie-chevron">
              {collapaseCTA ? "expand_more" : "chevron_right"}
            </i>
          </h2>

          <hr />
          <Collapse isOpen={collapaseCTA} className="responsive-collapse">
            {ctaContent ? (
              <div
                dangerouslySetInnerHTML={{ __html: ctaContent.contentBody }}
              />
            ) : (
              <ol>
                <li>Select a college and the first year you will attend.</li>
                <li>
                  Determine college costs using provided federal data or use
                  your price.
                </li>
                <li>
                  See how far your savings and cash will stretch to help pay for
                  college.
                </li>
                <li>Compare all of your eligible financing options.</li>
                <li>
                  Pick your preferred funding plan, get helpful next steps, and
                  look up another college to compare side-by-side.
                </li>
              </ol>
            )}
          </Collapse>
        </div>
      </Col>
    </>
  );
}

OnboardingHelp.propTypes = {
  ctaContent: PropTypes.object.isRequired,
};
