import React, { Component } from "react";
import PropTypes from "prop-types";
import { put } from "../../../utils/DeApi";
import { getStudentId } from "../../../utils/AuthManager";

import YearPicker from "./YearPicker/YearPicker";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import Loader from "../../Loader/Loader";

class EnrollmentYear extends Component {
  static propTypes = {
    student: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      enrollmentYear: props.student.enrollmentYear
    };

    this.handleEnrollmentYearChange = this._handleEnrollmentYearChange.bind(
      this
    );
  }

  _handleEnrollmentYearChange(enrollmentYear) {
    this.setState({ enrollmentYear: enrollmentYear }, () =>
      this.updateStudent()
    );
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  updateStudent() {
    const { onStudentUpdated } = this.props;
    const { enrollmentYear } = this.state;

    this.setState({ error: "", isLoading: true });

    let studentPromise = put(
      "/profile",
      getStudentId({ enrollmentYear: enrollmentYear })
    );
    studentPromise.promise
      .then(response => {
        this.setState({ error: "", isLoading: false }, () =>
          onStudentUpdated(response.data)
        );
      })
      .catch(error => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(studentPromise);
  }

  render() {
    const { enrollmentYear, isLoading, error } = this.state;
    if (isLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;
    return (
      <div className="EnrollmentYear">
        <p>
          What is the first fall semester the student would be starting in
          college?
        </p>

        <div className="mt-3 mb-3">
          <YearPicker
            enrollmentYear={enrollmentYear}
            onYearChange={this.handleEnrollmentYearChange}
          />
        </div>
      </div>
    );
  }
}

export default EnrollmentYear;
