import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Numeral from "numeral";

import { Button, Form, FormGroup, FormFeedback, Input } from "reactstrap";

import { put } from "../../../utils/DeApi";
import { getStudentId } from "../../../utils/AuthManager";

import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import Loader from "../../Loader/Loader";

class Cash extends Component {
  static propTypes = {
    student: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      cash: props.student.parentMonthlyAffordability
    };
    this.handleCashChange = this._handleCashChange.bind(this);
    this.submitCash = this._submitCash.bind(this);
  }

  _handleCashChange(event) {
    let cash = Numeral(event.target.value).value() || 0;
    if (cash >= 0)
      this.setState({
        cash: cash,
        cashValid: cash && cash >= 0
      });
  }

  _submitCash(event) {
    event.preventDefault();
    this.updateStudent();
  }

  componentDidMount() {
    Numeral.defaultFormat("$0,0");
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  updateStudent() {
    const { onStudentUpdated } = this.props;
    const { cash } = this.state;

    this.setState({ error: "", isLoading: true });

    let studentPromise = put(
      "/profile",
      getStudentId({ parentMonthlyAffordability: cash })
    );
    studentPromise.promise
      .then(response => {
        this.setState({ error: "", isLoading: false }, () =>
          onStudentUpdated(response.data)
        );
      })
      .catch(error => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(studentPromise);
  }

  render() {
    const { cash, cashValid, cashSubmited, isLoading, error } = this.state;

    if (isLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;

    return (
      <Fragment>
        <p>
          While in college, estimate what your family can contribute monthly
          from income and any sources other than savings?
        </p>
        <p className="text-muted">
          Cash payments can be used to cover tuition payments and other expenses
          like housing. These payments can really help reduce long-term
          borrowing costs.
        </p>
        <Form onSubmit={this.submitCash}>
          <FormGroup>
            <Input
              id="monthlyAffordability"
              type="text"
              disabled={isLoading}
              value={Numeral(cash).format()}
              onChange={this.handleCashChange}
              invalid={!cashValid && cashSubmited}
              placeholder="Monthly Contribution"
            />
            <FormFeedback>
              This should be a number greater than or equal to 0
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Button
              color="primary"
              size="sm"
              type="submit"
              disabled={isLoading}
              block
            >
              Submit
            </Button>
          </FormGroup>
        </Form>
      </Fragment>
    );
  }
}

export default Cash;
