import React, { Component, Fragment } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { isLoggedIn } from "../../../utils/BeeApi";
import { Helmet } from "react-helmet";
import Breadcrumber from "./Breadcrumber/Breadcrumber";
import Feedback from "../Feedback/Feedback";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
    this.handleToggle = this._handleToggle.bind(this);
    this.handleCollapseMenu = this._handleCollapseMenu.bind(this);
  }

  _handleToggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  _handleCollapseMenu() {
    if (this.state.isOpen)
      this.setState({
        isOpen: !this.state.isOpen,
      });
  }

  render() {
    const { isOpen } = this.state;
    let user = isLoggedIn();
    let title = this.props.title || "";
    let breadcrumbs = this.props.breadcrumbs;

    return (
      <div className="MainHeader mb-4">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{"Quatromoney" + (title ? "- " + title : "")}</title>
        </Helmet>
        <Navbar
          color="light"
          light
          expand="md"
          className="border-bottom"
          fixed="top"
        >
          <NavbarBrand href="https://www.quatromoney.com/">
            {breadcrumbs && breadcrumbs.length ? (
              <img
                className="brand-icon"
                src="/favicon.png"
                alt="Quatro Money"
              />
            ) : (
              <img
                className="brand-logo"
                src="/quatro-logo.png"
                alt="Quatro Money"
              />
            )}
          </NavbarBrand>
          <NavbarToggler onClick={this.handleToggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <Breadcrumber
                breadcrumbs={breadcrumbs}
                onToggle={this.handleCollapseMenu}
              />
            </Nav>
            <Nav className="ml-auto nav-right" navbar>
              <Feedback user={user} />

              {user.email ? (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    <span className="material-icons mr-1">account_circle</span>
                    {user.firstName}
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem
                      tag={Link}
                      to="/account"
                      className=" text-capitalize"
                    >
                      Your Profile
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem tag={Link} to="/logout">
                      Logout
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) : (
                <Fragment>
                  <NavItem>
                    <NavLink tag={Link} to="/login">
                      Login
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to="/signup">
                      Signup
                    </NavLink>
                  </NavItem>
                </Fragment>
              )}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Header;
