import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Button,
  Alert,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback
} from "reactstrap";

import "./ResetPassword.css";
import { isLoggedIn, put } from "../../../utils/BeeApi";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

import { isLoggedIn as isLoggedInDE } from "../../../utils/DeApi";
import { isPassword, isEmail } from "../../../utils/StringUtils";

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this._handleSubmit.bind(this);
    this.handlePasswordChange = this._handlePasswordChange.bind(this);
    this.handleConfirmPasswordChange = this._handleConfirmPasswordChange.bind(
      this
    );
    this.handleEmailChange = this._handleEmailChange.bind(this);

    const loggedIn = isLoggedInDE() && isLoggedIn();
    let email = loggedIn ? loggedIn.email : "";
    let validationToken = this.parseURLParams(props.location.search).get(
      "verification"
    );

    this.state = {
      email: email,
      emailValid: isEmail(email),
      password: "",
      confirmPassword: "",
      passwordValid: false,
      confirmPasswordValid: false,
      error: "",
      loggedIn: loggedIn,
      validationToken: validationToken || "",
      formSubmitted: false
    };

    this.subscribedPromises = [];
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  parseURLParams(params) {
    return new URLSearchParams(params || "");
  }

  _handleEmailChange(event) {
    let email = event.target.value;
    this.setState({
      email: email,
      emailValid: isEmail(email)
    });
  }

  _handlePasswordChange(event) {
    let password = event.target.value;
    this.setState({
      password: password,
      passwordValid: isPassword(password)
    });
  }

  _handleConfirmPasswordChange(event) {
    const { password } = this.state;
    let confirmPassword = event.target.value;
    this.setState({
      confirmPassword: confirmPassword,
      confirmPasswordValid:
        isPassword(confirmPassword) && confirmPassword === password
    });
  }

  _handleSubmit(event) {
    event.preventDefault();

    const { confirmPassword, password } = this.state;

    this.setState(
      {
        formSubmitted: true,
        confirmPasswordValid: confirmPassword === password
      },
      (state, props) => {
        const { emailValid, passwordValid, confirmPasswordValid } = this.state;

        if (emailValid && passwordValid && confirmPasswordValid) {
          this.setState({
            error: "",
            isLoading: true
          });

          const createPromise = put("/user/anon/password", {
            userId: "anon",
            email: this.state.email,
            token: this.state.validationToken,
            password: this.state.password,
            password_confirmation: this.state.confirmPassword
          });
          createPromise.promise
            .then(response => {
              this.setState({
                isLoading: false,
                error: ""
              });

              //On success redirect to login.
              this.props.history.push({
                pathname: "/login",
                state: this.state
              });
            })
            .catch(error => {
              this.setState({
                error: error,
                isLoading: false
              });
            });

          this.subscribedPromises.push(createPromise);
        }
      }
    );
  }

  renderResetPasswordForm() {
    const {
      email,
      emailValid,
      password,
      passwordValid,
      confirmPassword,
      confirmPasswordValid,
      error,
      formSubmitted
    } = this.state;

    return (
      <Form onSubmit={this.handleSubmit}>
        <h2 className="text-center">Set Password</h2>
        <p className="text-center">
          Enter your email and a new password for your account.
        </p>
        <hr />
        <FormGroup>
          <Label htmlFor="loginEmail">Email address</Label>
          <Input
            type="email"
            id="loginEmail"
            value={email}
            onChange={this.handleEmailChange}
            valid={emailValid}
            invalid={!emailValid && formSubmitted}
            placeholder="Enter email"
            autoFocus
          />
          <FormFeedback>This should be a valid email address</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="loginEmail">Password</Label>
          <Input
            id="loginPassword"
            type="password"
            value={password}
            onChange={this.handlePasswordChange}
            valid={passwordValid}
            invalid={!passwordValid && formSubmitted}
            placeholder="Password"
          />
          <FormFeedback>
            Password must contain at least 6 characters, including
            UPPER/lowercase and numbers.
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="confirmPassword">Confirm Password</Label>
          <Input
            id="confirmPassword"
            type="password"
            value={confirmPassword}
            onChange={this.handleConfirmPasswordChange}
            valid={confirmPasswordValid}
            invalid={!confirmPasswordValid && formSubmitted}
            placeholder="Confirm Password"
          />
          <FormFeedback>Confirm password must match with password</FormFeedback>
        </FormGroup>

        {error &&
          (error => {
            if (error.status === 403) {
              return (
                <Alert color="warning" className="mt-md mb-md">
                  <p>
                    Sorry, your link to set a new password has expired or is
                    invalid. Please request for another link{" "}
                    <Link to="/login-help">here</Link>.
                  </p>
                </Alert>
              );
            } else {
              return <ErrorHandler error={error} />;
            }
          })(error)}

        <Button className="mb-4" type="submit" color="primary" size="lg" block>
          Continue
        </Button>
        {/* <hr /> */}
        <p className="text-center">
          <Link to="/login">Cancel</Link>
        </p>
      </Form>
    );
  }

  render() {
    const { isLoading, loggedIn } = this.state;
    if (loggedIn) {
      let { from } = this.props.location.state || {
        from: { pathname: "/" }
      };
      return <Redirect to={from} />;
    }

    if (isLoading) return <Loader />;

    return (
      <Container fluid className="ResetPassword mt-5">
        <Row>
          <Col sm="12" md={{ size: 4, offset: 4 }}>
            <div className="text-center">
              <Link to="/">
                <img
                  className="img-fluid mb-4"
                  width="300"
                  src="/quatro-logo.png"
                  alt="logo"
                />
              </Link>
            </div>
            <Card className="mt-2">
              <CardBody>{this.renderResetPasswordForm()}</CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ResetPassword;
