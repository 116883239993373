import React, { Component } from "react";
import GoogleAnalytics from "react-ga";
GoogleAnalytics.initialize("UA-136961396-2");

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    GoogleAnalytics.set({
      page,
      ...options
    });
    GoogleAnalytics.pageview(page);
  };

  // eslint-disable-next-line
  class HOC extends Component {
    componentDidMount() {
      // eslint-disable-next-line
      const page = this.props.location.pathname;
      trackPage(page);
    }

    componentDidUpdate(prevProps) {
      const currentPage = prevProps.location.pathname;
      const nextPage = this.props.location.pathname;
      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      let configObject = Object.assign(options, this.props);
      return <WrappedComponent {...configObject} />;
    }
  }

  return HOC;
};

export default withTracker;
