import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import "./ScenarioDelete.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { destroy } from "../../../utils/DeApi";
import { getStudentId } from "../../../utils/AuthManager";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class ScenarioDelete extends Component {
  static propTypes = {
    scenarioId: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { modal: false };
    this.toggle = this.toggle.bind(this);
    this.subscribedPromises = [];
    this.deleteScenario = this._deleteScenario.bind(this);
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  _deleteScenario() {
    const { scenarioId } = this.props;
    this.setState({ isLoading: true, error: "" });

    let scenariosPromise = destroy(`/scenarios/${scenarioId}`, {
      data: getStudentId({})
    });
    scenariosPromise.promise
      .then(response => {
        this.setState(
          {
            modal: false,
            error: "",
            isLoading: false
          },
          () => {
            this.props.handleDelete(scenarioId);
          }
        );
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error,
            isLoading: false
          });
      });
    this.subscribedPromises.push(scenariosPromise);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  render() {
    const { isLoading, error, modal } = this.state;

    return (
      <Fragment>
        <button className="close" onClick={this.toggle}>
          <span aria-hidden="true">×</span>
        </button>
        <Modal
          isOpen={modal}
          fade={false}
          toggle={this.toggle}
          container={".MainContent"}
        >
          <ModalHeader toggle={this.toggle} tag={"h3"}>
            Confirm delete
          </ModalHeader>
          <ModalBody>
            {isLoading && <Loader />}
            {!isLoading && (
              <p className="mb-0">
                Do you really want to delete this affordability plan?
              </p>
            )}
            {error && <ErrorHandler error={error} />}
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>
              Cancel
            </Button>
            <Button color="primary" onClick={this.deleteScenario}>
              Delete
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default ScenarioDelete;
