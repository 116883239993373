import { sum, sumBy, maxBy, minBy } from "lodash";

function getInSchoolPayments(loans) {
  let inSchoolPayments = [];

  loans.forEach((loan) => {
    loan.schedule.forEach((item, index) => {
      if (item.studentStatus !== "Post Graduation")
        inSchoolPayments[index] =
          Number(item.interestPayment) +
          Number(item.principalPayment) +
          Number(inSchoolPayments[index] || 0);
      else inSchoolPayments[index] = inSchoolPayments[index] || null;
    });
  });

  return inSchoolPayments.filter(Boolean);
}

function getHighestInSchoolPayment(loans) {
  return maxBy(getInSchoolPayments(loans));
}

function getLowestInSchoolPayment(loans) {
  return minBy(getInSchoolPayments(loans));
}

function getPostGradPayments(loans) {
  let postGradPayments = [];

  loans.forEach((loan) => {
    loan.schedule.forEach((item, index) => {
      if (item.studentStatus === "Post Graduation")
        postGradPayments[index] =
          Number(item.interestPayment) +
          Number(item.principalPayment) +
          Number(postGradPayments[index] || 0);
      else postGradPayments[index] = postGradPayments[index] || null;
    });
  });
  return postGradPayments.filter(Boolean);
}

function getLowestPostGradPayment(loans) {
  return minBy(getPostGradPayments(loans));
}

function getHighestPostGradPayment(loans) {
  return maxBy(getPostGradPayments(loans));
}

function getTotalOfPayments(loans) {
  let borrowedAmount = 0;
  let interestPayments = 0;
  let accruedInterest = 0;
  let closingCosts = 0;

  loans.forEach((loan) => {
    borrowedAmount += sum([
      loan.froshLoanAmount,
      loan.sophomoreLoanAmount,
      loan.juniorLoanAmount,
      loan.seniorLoanAmount,
    ]);

    interestPayments += sumBy(loan.schedule, (item) => {
      return Number(item.interestPayment);
    });

    accruedInterest += maxBy(loan.schedule, "accruedInterest").accruedInterest;
    closingCosts += Number(loan.closingCosts) || 0;
  });

  return borrowedAmount + interestPayments + accruedInterest + closingCosts;
}

function getBorrowing(loans) {
  let borrowedAmount = 0;

  loans.forEach((loan) => {
    const {
      froshLoanAmount = 0,
      sophomoreLoanAmount = 0,
      juniorLoanAmount = 0,
      seniorLoanAmount = 0,
    } = loan;
    borrowedAmount += sum([
      froshLoanAmount,
      sophomoreLoanAmount,
      juniorLoanAmount,
      seniorLoanAmount,
    ]);
  });

  return borrowedAmount;
}

function getBorrowingFees(loans) {
  let fees = 0;
  loans.forEach((loan) => {
    fees += sum([
      loan.froshYearlyFees,
      loan.sophomoreYearlyFees,
      loan.juniorYearlyFees,
      loan.seniorYearlyFees,
    ]);
  });

  return fees;
}

function getFinancingCost(loans) {
  let interestPayments = 0;
  let accruedInterest = 0;
  let closingCosts = 0;

  loans.forEach((loan) => {
    interestPayments += sumBy(loan.schedule, (item) => {
      return Number(item.interestPayment);
    });
    accruedInterest += maxBy(loan.schedule, "accruedInterest").accruedInterest;
    closingCosts += Number(loan.closingCosts);
  });

  return interestPayments + accruedInterest + closingCosts;
}

function getPrincipal(schedule) {
  let principalPayments = sumBy(schedule, (item) => {
    return Number(item.principalPayment);
  });

  return principalPayments;
}

function getTotalOfInterest(schedule) {
  let interestPayments = sumBy(schedule, (item) => {
    return Number(item.interestPayment);
  });

  return interestPayments;
}

export {
  //inschool
  getInSchoolPayments,
  getLowestInSchoolPayment,
  getHighestInSchoolPayment,
  // post grad
  getPostGradPayments,
  getLowestPostGradPayment,
  getHighestPostGradPayment,
  // schedule
  getTotalOfPayments,
  getBorrowing,
  getBorrowingFees,
  getFinancingCost,
  getPrincipal,
  getTotalOfInterest,
};
