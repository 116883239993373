import React, { Component, Fragment } from "react";
import remove from "lodash/remove";
import isEmpty from "lodash/isEmpty";
import Numeral from "numeral";
import {
  Button,
  Form,
  Input,
  Badge,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
  Row,
  Col,
} from "reactstrap";

import "./ScenarioList.scss";

import { get } from "../../../utils/DeApi";
import { getStudentId } from "../../../utils/AuthManager";
import { isLoggedIn as isLoggedInDE } from "../../../utils/DeApi";

import { getSchoolMonths } from "../../../enumerator/enumerator";
import { getTotalOfPayments } from "../../../enumerator/FinancingOptionCalculators";
import { getCollegeValueFactor } from "../CollegeValue/CollegeValueSummary/CollegeValueSummary";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import ScenarioSummary from "../ScenarioSummary/ScenarioSummary";
import Savings from "../../Student/Savings/Savings";
import Cash from "../../Student/Cash/Cash";
import CreditScore from "../../Student/CreditScore/CreditScore";
import Borrower from "../../Student/Borrower/Borrower";

class ScenarioList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      student: props.student,
      bookend: true,
      limit: 10,
      sort: "schoolName",
      offset: 0,
    };
    this.subscribedPromises = [];
    this.handleDelete = this._handleDelete.bind(this);
    this.handleBookend = this._handleBookend.bind(this);
    this.handleSortChange = this._handleSortChange.bind(this);
    this.handleFundingGapReceived = this._handleFundingGapReceived.bind(this);
    this.handleEstimatedFinancingReceived =
      this._handleEstimatedFinancingReceived.bind(this);
    this.handleStudentUpdated = this._handleStudentUpdated.bind(this);
  }

  componentDidMount() {
    this.fetchScenarios();
    this.fetchCreditRanges();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
  }

  _handleSortChange(event) {
    let sort = event.target.value;
    this.setState({ isLoading: true }, () => {
      setTimeout(() => this.setState({ isLoading: false, sort: sort }), 300);
    });
  }

  _handleFundingGapReceived(scenarioId, fundingGap) {
    let { scenarios } = this.state;
    scenarios = scenarios.map((scenario) => {
      if (scenario.id === scenarioId) {
        scenario.fundingGap = fundingGap;
      }
      return scenario;
    });

    this.setState({ scenarios });
  }

  _handleEstimatedFinancingReceived(scenarioId, estimatedFinancing) {
    let { scenarios } = this.state;
    scenarios = scenarios.map((scenario) => {
      if (scenario.id === scenarioId) {
        scenario.estimatedFinancing = estimatedFinancing;
      }
      return scenario;
    });
    this.setState({ scenarios });
  }

  _handleDelete(scenarioId) {
    let { scenarios } = this.state;
    remove(scenarios || [], (scenario) => {
      return scenario.id === scenarioId;
    });
    this.setState({
      scenarios: scenarios || [],
    });
  }

  _handleStudentUpdated(student) {
    this.setState(
      { student: student, personalization: null, scenarios: null },
      () => this.fetchScenarios()
    );
  }

  fetchScenarios() {
    const { limit, offset } = this.state;
    this.setState({ isLoading: true, error: "" });

    if (!isLoggedInDE() && isEmpty(getStudentId())) {
      this.setState({ scenarios: [], error: "", isLoading: false });
    } else {
      let scenariosPromise = get("/scenarios", {
        params: getStudentId({ limit: limit || 10, offset: offset || 0 }),
      });
      scenariosPromise.promise
        .then((response) => {
          this.setState(
            { scenarios: response.data, error: "", isLoading: false },
            () => {
              if (response.data.length) {
                response.data.forEach((scenario) => {
                  this.fetchAnnualNetPrice(scenario);
                  this.fetchFinancingOption(scenario);
                });
              }
            }
          );
        })
        .catch((error) => {
          !error.isCanceled &&
            this.setState({ error: error, isLoading: false });
        });
      this.subscribedPromises.push(scenariosPromise);
    }
  }

  fetchAnnualNetPrice(scenario) {
    this.setState({ isLoadingPrice: true, priceError: "" });

    let pricingPromise = get(`/scenarios/${scenario.id}/annual-deficit`, {
      params: getStudentId(),
    });

    pricingPromise.promise
      .then((response) => {
        let price = response.data;
        const {
          froshDeficit = 0,
          sophomoreDeficit = 0,
          juniorDeficit = 0,
          seniorDeficit = 0,
        } = price.annualDeficit;
        const fundingGap =
          froshDeficit + sophomoreDeficit + juniorDeficit + seniorDeficit;

        if (!isNaN(fundingGap)) {
          this.handleFundingGapReceived(scenario.id, fundingGap);
        }
        this.setState({ priceError: "", isLoadingPrice: false });
      })
      .catch((error) => {
        !error.isCanceled &&
          this.setState({ priceError: error, isLoadingPrice: false });
      });

    this.subscribedPromises.push(pricingPromise);
  }

  fetchFinancingOption(scenario) {
    if (!scenario.preferredLoanId) return;
    this.setState({ foIsLoading: true, foError: "" });

    let foPromise = get(`/financing-options/${scenario.preferredLoanId}`, {
      params: getStudentId({ scenarioId: scenario.id }),
    });

    foPromise.promise
      .then((response) => {
        let fo = response.data;
        const totalOfPayments = getTotalOfPayments(fo.loans);
        this.handleEstimatedFinancingReceived(scenario.id, totalOfPayments);
        this.setState({
          scenarios: this.state.scenarios.map((item) => {
            if (item.id === scenario.id) item.financingOption = fo;
            return item;
          }),
          foError: null,
          foIsLoading: false,
        });
      })
      .catch((error) => {
        !error.isCanceled &&
          this.setState({ foError: error, foIsLoading: false });
      });
    this.subscribedPromises.push(foPromise);
  }

  fetchCreditRanges() {
    var { student } = this.state;
    var creditGroups = [
      { value: "Excellent", label: "Excellent: 800 – 850" },
      { value: "Very Good", label: "Strong: 750 – 799" },
      { value: "Good", label: "Good: 690 – 749" },
      { value: "Poor", label: "Not So Good: ≦ 689" },
    ];

    let creditRangesPromise = get("/credit-ratings", {
      params: getStudentId({}),
    });

    creditRangesPromise.promise
      .then((response) => {
        if (response.data?.length) {
          creditGroups = [];
          response.data.forEach((group) => {
            creditGroups.push({
              value: group.name,
              label: group.range || group.name,
            });
          });
        }

        var selected = creditGroups.find((group) => {
          return group.value === student.creditRating;
        });
        this.setState({
          creditGroups: creditGroups,
          selectedCreditScore: selected || null,
        });
      })
      .catch((error) => {
        !error.isCanceled && console.warn(error);
      });

    this.subscribedPromises.push(creditRangesPromise);
  }

  render() {
    const { isLoading, error } = this.state;

    return (
      <div className="ScenarioList mt-3 mb-5">
        {isLoading ? (
          <Loader />
        ) : error ? (
          <ErrorHandler error={error} />
        ) : (
          this.renderScenarios()
        )}
      </div>
    );
  }

  renderScenarios() {
    const { scenarios, sort, isLoading } = this.state;

    if (scenarios) {
      if (sort === "schoolName") {
        scenarios.sort((scenarioOne, scenarioTwo) => {
          let schoolOne = scenarioOne.school.name.toLowerCase();
          let schoolTwo = scenarioTwo.school.name.toLowerCase();
          return schoolOne.localeCompare(schoolTwo);
        });
      } else if (sort === "fundingGap") {
        scenarios.sort((scenarioOne, scenarioTwo) => {
          let fundingGapOne = scenarioOne.fundingGap || 0;
          let fundingGapTwo = scenarioTwo.fundingGap || 0;
          return fundingGapOne - fundingGapTwo;
        });
      } else if (sort === "estimatedFinancing") {
        scenarios.sort((scenarioOne, scenarioTwo) => {
          let estimatedFinancingOne = scenarioOne.estimatedFinancing || 0;
          let estimatedFinancingTwo = scenarioTwo.estimatedFinancing || 0;
          return estimatedFinancingOne - estimatedFinancingTwo;
        });
      } else if (sort === "collegeValueFactor") {
        scenarios.sort((scenarioOne, scenarioTwo) => {
          let cvfOne = scenarioOne.financingOption
            ? getCollegeValueFactor(scenarioOne, scenarioOne.financingOption)
            : 0;
          let cvfTwo = scenarioTwo.financingOption
            ? getCollegeValueFactor(scenarioTwo, scenarioTwo.financingOption)
            : 0;
          return cvfTwo - cvfOne;
        });
      } else if (sort === "typeOfCollege") {
        scenarios.sort((scenarioOne, scenarioTwo) => {
          let sectorOne = scenarioOne.school.sector.toLowerCase();
          let sectorTwo = scenarioTwo.school.sector.toLowerCase();
          return sectorOne.localeCompare(sectorTwo);
        });
      }
    }

    return (
      <Fragment>
        <div className="mt-3 mb-3">
          <Row>
            <Col xs={12} md={8}>
              <h3>Your Colleges</h3>
            </Col>
            <Col xs={12} md={4}>
              <div className="d-flex float-right">
                <small className="mt-1 mr-1">Sort by</small>
                <Form>
                  <Input
                    type="select"
                    bsSize="sm"
                    value={sort}
                    disabled={isLoading}
                    onChange={this.handleSortChange}
                  >
                    <option value="schoolName">School Name</option>
                    <option value="fundingGap">Financing Need</option>
                    <option value="estimatedFinancing">Financing Option</option>
                    <option value="collegeValueFactor">
                      College Value Factor™
                    </option>
                    <option value="typeOfCollege">Type Of College</option>
                  </Input>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
        {!scenarios || !scenarios.length ? (
          <Fragment>
            <p className="mt-3 mb-3">
              You currently have no affordability plans.
            </p>
            <p>
              Search for a school to get started or log in to view your previous
              searches.
            </p>
          </Fragment>
        ) : (
          <div className="ScenaroList card mt-3 mb-3">
            <div className="card-body">
              {this.renderPersonalization()}
              <hr />
              {scenarios.map((item, index) => {
                return (
                  <Fragment key={index}>
                    <ScenarioSummary
                      handleDelete={this.handleDelete}
                      scenario={item}
                    />
                    <hr className="mt-0" />
                  </Fragment>
                );
              })}
            </div>
          </div>
        )}
      </Fragment>
    );
  }

  renderPersonalization() {
    const { student, personalization, selectedCreditScore, creditGroups } =
      this.state;
    return (
      <div className="ScenarioPersonalization">
        <small>Your Financial Profile: </small>
        <Dropdown
          tag="span"
          isOpen={personalization === "savings"}
          toggle={() => {
            this.setState({
              personalization: personalization === "savings" ? null : "savings",
            });
          }}
        >
          <DropdownToggle
            tag="button"
            size="sm"
            className="btn btn-outline-link"
          >
            {student.parentSavings
              ? `${Numeral(
                  student.parentSavings / getSchoolMonths() // getSchoolMonths returns default 48 months if schoolYears is not passed
                ).format()}/mo from savings`
              : "Savings"}{" "}
            <span className="material-icons">arrow_drop_down</span>
          </DropdownToggle>
          <DropdownMenu
            tag="span"
            container="body"
            className="ScenarioListDropdown"
          >
            <Savings
              student={student}
              onStudentUpdated={this.handleStudentUpdated}
            />
          </DropdownMenu>
        </Dropdown>{" "}
        <Dropdown
          tag="span"
          isOpen={personalization === "cash"}
          toggle={() => {
            this.setState({
              personalization: personalization === "cash" ? null : "cash",
            });
          }}
        >
          <DropdownToggle
            tag="button"
            size="sm"
            className="btn btn-outline-link"
          >
            {student.parentMonthlyAffordability
              ? `${Numeral(
                  student.parentMonthlyAffordability
                ).format()}/mo from cash`
              : "Cash"}{" "}
            <span className="material-icons">arrow_drop_down</span>
          </DropdownToggle>
          <DropdownMenu
            tag="span"
            container="body"
            className="ScenarioListDropdown"
          >
            <Cash
              student={student}
              onStudentUpdated={this.handleStudentUpdated}
            />
          </DropdownMenu>
        </Dropdown>{" "}
        <Dropdown
          tag="span"
          isOpen={personalization === "borrowers"}
          toggle={() => {
            this.setState({
              personalization:
                personalization === "borrowers" ? null : "borrowers",
            });
          }}
        >
          <DropdownToggle
            tag="button"
            size="sm"
            className="btn btn-outline-link"
          >
            Borrowers{" "}
            {student.preferParentPaying && (
              <Badge className="text-capitalize ml-1">Parent</Badge>
            )}
            {student.preferParentPaying && (
              <Badge className="text-capitalize ml-1">Student</Badge>
            )}
            <span className="material-icons">arrow_drop_down</span>
          </DropdownToggle>
          <DropdownMenu
            tag="span"
            container="body"
            className="ScenarioListDropdown"
          >
            <Borrower
              student={student}
              onStudentUpdated={this.handleStudentUpdated}
            />
          </DropdownMenu>
        </Dropdown>{" "}
        <Dropdown
          tag="span"
          isOpen={personalization === "credit-score"}
          toggle={() => {
            this.setState({
              personalization:
                personalization === "credit-score" ? null : "credit-score",
            });
          }}
        >
          <DropdownToggle
            tag="button"
            size="sm"
            className="btn btn-outline-link"
          >
            Credit Score{" "}
            {selectedCreditScore && (
              <Badge className="text-capitalize ml-1">
                {selectedCreditScore.label}
              </Badge>
            )}
            <span className="material-icons">arrow_drop_down</span>
          </DropdownToggle>
          <DropdownMenu
            tag="span"
            container="body"
            className="ScenarioListDropdown"
          >
            <CreditScore
              student={student}
              onStudentUpdated={this.handleStudentUpdated}
              creditGroups={creditGroups}
              selectedCreditRating={(rating) => {
                this.setState({ selectedCreditScore: rating });
              }}
            />
          </DropdownMenu>
        </Dropdown>{" "}
      </div>
    );
  }
  expandScenarios(offset) {
    this.setState({
      expandingError: "",
      isExpanding: true,
      offset: offset,
    });

    const { limit } = this.state;

    const scenariosPromise = get("/scenarios", {
      params: getStudentId({
        limit: limit || 10,
        offset: offset || 0,
      }),
    });
    scenariosPromise.promise
      .then((response) => {
        this.setState((prevState) => {
          return {
            scenarios: prevState.scenarios
              ? prevState.scenarios.concat(...response.data)
              : response.data,
            error: "",
            isExpanding: false,
          };
        });
      })
      .catch((error) => {
        !error.isCanceled &&
          this.setState({
            isExpanding: false,
            expandingError: error,
          });
      });

    this.subscribedPromises.push(scenariosPromise);
  }

  _handleBookend() {
    const { scenarios } = this.state;
    let offset = this.state.offset
      ? this.state.offset + scenarios.length
      : scenarios.length;
    this.expandScenarios(offset);
  }

  renderBookend() {
    const { isExpanding, expandingError, scenarios, bookend, limit } =
      this.state;

    if (expandingError)
      return (
        <div xs={12} className="mb-5">
          <ErrorHandler error={expandingError} />
        </div>
      );

    if (isExpanding)
      return (
        <div xs={12} className="mb-5">
          <Loader />
        </div>
      );

    if (scenarios.length <= 0 || !bookend) return <div />;

    return (
      scenarios.length % limit === 0 && (
        <div xs={12} className="text-center mt-5">
          <Button onClick={this.handleBookend}>Show more scenarios</Button>
        </div>
      )
    );
  }
}

export default ScenarioList;
